let globalDispatch = null;

/**
 *  전역 dispatch 설정 함수
 */
export const setGlobalDispatch = (dispatch) => {
    if (typeof dispatch !== "function") {
        console.error("🚨 setGlobalDispatch 실행 오류 - 전달된 dispatch가 함수가 아닙니다:", dispatch);
        return;
    }

    if (dispatch.name === "dispatchReducerAction") {
        console.warn("🚨 dispatchReducerAction이 저장되지 않도록 차단됨.");
        return;
    }

    console.log("✅ setGlobalDispatch 실행됨 - dispatch 설정됨:", dispatch);
    globalDispatch = dispatch;
};

/**
 * 전역 dispatch 가져오기
 */
export const getGlobalDispatch = () => {
    console.log("✅ getGlobalDispatch 호출됨 - dispatch:", globalDispatch);
    if (!globalDispatch) {
        console.warn("🚨 dispatch가 아직 설정되지 않음! setGlobalDispatch가 호출되었는지 확인 필요.");
    }
    return globalDispatch;
};
