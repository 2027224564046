/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';

// ====================== MUI Components  =========================
import theme from '../../assets/theme';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// ====================== Service Components ======================
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';

// ====================== Images ==================================

function Calendar({ itemCounts, holidays, onDateChange, clickDate }) {
  // const [calendarEvents, setCalendarEvents] = useState(events);
  const [filterState, setFilterState] = useState({
    all: true,
    name_tl: true,
    auto: true,
    manual: true,
  });

  const calendarRef = useRef(null);

  const handleDatesSet = (arg) => {
    const { start, end, startStr, endStr, timeZone } = arg;
    function getFormattedDateFromSession(startStr, endStr, monthOffset) {
      const dateString = sessionStorage.getItem('calendarDate');
      let date;
      if (dateString) {
        date = new Date(dateString); // 세션 스토리지의 날짜를 기준으로 설정
      } else {
        date = monthOffset < 0 ? new Date(startStr) : new Date(endStr);
        return date
      }
      date.setMonth(date.getMonth() + monthOffset);
      return date;
    }
    // const date1 = new Date(startStr);
    const date1 = getFormattedDateFromSession(startStr, endStr, -1);
    let year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 2;
    // const date2 = new Date(endStr);
    const date2 = getFormattedDateFromSession(startStr, endStr, 1);
    const month2 = date2.getMonth();
    // TODO 초기 호출이후 값이 pnr과 Memo를 가져오지 못하는 현상이 있어 Session Storage값 제거
    sessionStorage.removeItem('calendarDate');
    if (month1 == 13 && month2 == 1) {
      year1 = year1 + 1;
    }
    let month = '';
    if (month1 == month2) {
      month = month1;
    } else {
      if (month2 == 0) {
        month = 12;
      } else {
        month = month2;
      }
    }

    onDateChange(year1, month);
  };

  useEffect(() => {
    if (!calendarRef.current) return;
    // const lastToolbarChunk = document.querySelector(
    //   '.fc-header-toolbar .fc-toolbar-chunk:last-child'
    // );
    // if (lastToolbarChunk && !lastToolbarChunk.querySelector('.MuiFormGroup-root')) {
    //   const checkboxContainer = (
    //     <ThemeProvider theme={theme}>
    //       <FormGroup>
    //         {checkboxInfoArray.map((infoItem) => (
    //           <FormControlLabel
    //             key={infoItem.value}
    //             control={
    //               <Checkbox
    //                 checked={filterState[infoItem.value]}
    //                 onChange={onChangeCheckbox}
    //                 name={infoItem.value}
    //                 color="primary"
    //               />
    //             }
    //             label={infoItem.title}
    //           />
    //         ))}
    //       </FormGroup>
    //     </ThemeProvider>
    //   );

    //   const root = ReactDOM.createRoot(lastToolbarChunk);
    //   root.render(checkboxContainer);
    // }
    const dayCells = calendarRef.current.querySelectorAll('.fc-daygrid-day');

    dayCells.forEach((dayCell) => {
      const date = dayCell.getAttribute('data-date');
      if (!date || !itemCounts?.[date]) return;
      const dayBottom = dayCell.querySelector('.fc-daygrid-day-bottom');
      if (!dayBottom) return;

      // 기존 count-buttons 확인
      const existingCountButtons = dayBottom.querySelector('.count-buttons');
      const existingCounts = parseExistingCounts(existingCountButtons?.textContent);

      // 업데이트할 데이터 생성
      const updatedCounts = {
        PNR: itemCounts[date]?.pnr || 0,
        TTLA: itemCounts[date]?.ttl || 0,
        MEM: (existingCounts?.['MEMO'] ?? itemCounts[date]?.mem) || 0,
        TTLM: (existingCounts?.['TTLM'] ?? itemCounts[date]?.tlm) || 0,
        완료: (existingCounts?.['완료'] ?? itemCounts[date]?.completed) || 0,
        미완료: (existingCounts?.['미완료'] ?? itemCounts[date]?.pending) || 0,
        first_deposit_date: (existingCounts?.['first_deposit_date'] ?? itemCounts[date]?.first_deposit_date) || 0,
        second_deposit_date: (existingCounts?.['second_deposit_date'] ?? itemCounts[date]?.second_deposit_date) || 0,
        penalty_10_date: (existingCounts?.['penalty_10_date'] ?? itemCounts[date]?.penalty_10_date) || 0,
        penalty_30_date: (existingCounts?.['penalty_10_date'] ?? itemCounts[date]?.penalty_30_date) || 0,
        penalty_50_date: (existingCounts?.['penalty_10_date'] ?? itemCounts[date]?.penalty_50_date) || 0,
      };

      // 데이터 비교
      if (JSON.stringify(existingCounts) === JSON.stringify(updatedCounts)) return;
      // 버튼 렌더링
      renderCountButtons(dayBottom, updatedCounts);
    });
  }, [itemCounts]);
  // 기존 텍스트를 파싱하여 객체로 변환하는 함수
  function parseExistingCounts(text) {
    if (!text) return {};
    const counts = {};
    text.split('건').forEach((segment) => {
      if (segment.trim()) {
        const [key, value] = segment.split(':');
        if (key && value) {
          counts[key.trim()] = parseInt(value.trim(), 10);
        }
      }
    });
    return counts;
  }
  // 버튼을 렌더링하는 함수
  function renderCountButtons(container, counts) {
    const countButtons = (
      <ThemeProvider theme={theme}>
        {counts.PNR + counts.TTLA + counts.MEM + counts.TTLM != 0 && (
          <div className="count-buttons">
            <Button variant="outlined" color="info" data-category="pnr">
              출발 PNR: {counts.PNR}건
            </Button>
            <Button variant="outlined" color="warning" data-category="ttl">
              TTLA: {counts.TTLA}건
            </Button>
            <Button variant="outlined" color="info" data-category="mem">
              MEMO: {counts.MEM}건
            </Button>
            <Button variant="outlined" color="warning" data-category="tlm">
              TTLM: {counts.TTLM}건
            </Button>
          </div>
        )}
        {counts.first_deposit_date != 0 && (
          <div className="count-buttons" >
            <Button color="info" data-category="first_deposit_date">
              🟢1%({counts.first_deposit_date})건
            </Button>
          </div>
        )}
        {counts.second_deposit_date != 0 && (
          <div className="count-buttons" >
            <Button color="info" data-category="second_deposit_date">
              🟢2%({counts.second_deposit_date})건
            </Button>
          </div>
        )}
        {counts.penalty_10_date != 0 && (
          <div className="count-buttons" >
            <Button color="warning" data-category="penalty_10_date">
              🔴10%({counts.penalty_10_date})건
            </Button>
          </div>
        )}
        {counts.penalty_30_date != 0 && (
          <div className="count-buttons" >
            <Button color="warning" data-category="penalty_30_date">
              🔴30%({counts.penalty_30_date})건
            </Button>
          </div>
        )}
        {counts.penalty_50_date != 0 && (
          <div className="count-buttons" >
            <Button color="warning" data-category="penalty_50_date">
              🔴50%({counts.penalty_50_date})건
            </Button>
          </div>
        )}


        {/* <Button variant="outlined" color="secondary" data-category="tlm">
            완료: {counts['완료']}건
          </Button>
          <Button variant="outlined" color="secondary" data-category="tlm">
            미완료: {counts['미완료']}건
          </Button> */}
        {/* <ButtonGroup variant="outlined" color="secondary" sx={{ width: '100%' }}>
            <Button>완료: {counts['완료']}건</Button>
            <Button>미완료: {counts['미완료']}건</Button>
          </ButtonGroup> */}
      </ThemeProvider >
    );

    const root = ReactDOM.createRoot(container);
    root.render(countButtons);
  }

  // 달력 우측 상단 필터 체크박스
  const checkboxInfoArray = [
    { title: '전체보기', value: 'all' },
    { title: '네임 TL', value: 'name_tl' },
    { title: '발권 TL(Auto)', value: 'auto' },
    { title: '발권 TL(Manual)', value: 'manual' },
  ];

  // 토요일, 일요일, 공휴일 표시
  const dayCellContent = (e) => {
    const dayString = e.date.toLocaleDateString('en-CA'); // 날짜 YYYY-MM-DD 형태로 추출
    const dayOfWeek = e.date.getDay(); // 일요일, 토요일 체크
    const defaultText = e.dayNumberText; // 날짜 기본 텍스트 '1일'

    if (holidays[dayString]) {
      return (
        <div className="fc-daygrid-day-top-box holiday">
          <div className="fc-daygrid-day-text">{defaultText}</div>
          <div className="fc-daygrid-day-holiday">{holidays[dayString]}</div>
        </div>
      );
    } else if (dayOfWeek === 0) {
      return (
        <div className="fc-daygrid-day-top-box sunday">
          <div className="fc-daygrid-day-text">{defaultText}</div>
        </div>
      );
    } else if (dayOfWeek === 6) {
      return (
        <div className="fc-daygrid-day-top-box saturday">
          <div className="fc-daygrid-day-text">{defaultText}</div>
        </div>
      );
    } else {
      return (
        <div className="fc-daygrid-day-top-box">
          <div className="fc-daygrid-day-text">{defaultText}</div>
        </div>
      );
    }
  };

  // 추가된 이벤트들 display: none 처리
  const eventDidMount = (arg) => {
    arg.el.style.display = 'none';
  };

  // 날짜 영역 클릭 시 호출
  const dateClick = (info) => {
    const allDayEls = document.querySelectorAll('.fc-day');
    const clickedDayEl = info.dayEl;

    allDayEls.forEach((dayEl) => {
      dayEl.style.backgroundColor = 'transparent';
    });

    clickedDayEl.style.backgroundColor = 'var(--grey-50)';

    clickDate(info.dateStr);
  };

  // 필터 체크 박스
  const onChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    setFilterState((prevState) => {
      const updatedState = { ...prevState, [name]: checked };
      if (name === 'all') {
        // '전체보기' 체크박스가 변경되었을 때
        Object.keys(updatedState).forEach((key) => {
          updatedState[key] = checked;
        });
      } else {
        // 개별 체크박스가 변경되었을 때
        const allChecked = Object.keys(updatedState)
          .filter((key) => key !== 'all')
          .every((key) => updatedState[key]);

        updatedState.all = allChecked;
      }
      return updatedState;
    });
  };

  const savedDate = sessionStorage.getItem('calendarDate');
  const initialDate = savedDate ? savedDate : new Date().toISOString().split('T')[0];

  return (
    <div ref={calendarRef}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialDate={initialDate}
        headerToolbar={{
          // left: 'today',
          left: '',
          center: 'prevYear prev title next nextYear',
          right: '',
        }}
        contentHeight="75vh"
        locale={koLocale}
        datesSet={handleDatesSet} // Attach the datesSet callback
        dayCellContent={dayCellContent}
        eventDidMount={eventDidMount}
        dateClick={dateClick}
      />
    </div>
  );
}

export default Calendar;
