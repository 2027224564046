/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import utripApi from '../../utils/api/utripApi';
import Cookies from 'js-cookie';
import { isLogIn } from '../../utils/session';
import { useUtripContextApi, setOpenDialogPopup } from '../../context';

// ====================== MUI Components  =========================
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

// ====================== Service Components ======================
// ====================== Images ==================================

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogIn?.loginId) {
      navigate('/calendar');
    }
  }, []);

  const imageType = 2; // 로그인 우측 이미지 타입
  const [controller, dispatch] = useUtripContextApi();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: 'onBlur' });
  const [alertOpen, setAlertOpen] = useState(false);
  const AlertClose = () => {
    setAlertOpen(false);
  };
  const [rememberId, setRememberId] = useState(true);

  const requestLogin = async (loginDto) => {
    try {
      Cookies.remove('access_token');
      const response = await utripApi.post('/apis/login', loginDto);
      if (response.data.message === 'success') {
        let loginResult = response.data;
        let rememberMe;
        let storageInfo;
        if (rememberId === true) {
          storageInfo = { ...loginResult, loginId: loginDto.uid };
        } else {
          storageInfo = { ...loginResult };
        }
        const at = new Date();
        const rt = new Date();

        localStorage.setItem('UtripRPA', JSON.stringify(storageInfo));
        // 자동로그인이 맞을 경우(쿠키는 똑같이 담고, localStorage, sessionStorage 유무로 로그아웃 시키기)
        at.setTime(at.getTime() + 1000 * 60 * 60 * 24 * 30); // 30일
        rt.setTime(rt.getTime() + 1000 * 60 * 60 * 24 * 90); // 90일
        Cookies.set('AccessToken', loginResult.token, { expires: at });
        Cookies.set('RefreshToken', loginResult.refresh_token, { expires: rt });
        window.location.href = '/calendar';
      } else if (response.data.message === 'fail') {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          title: '로그인 실패',
          content: response.data.detail,
          reload: false,
        });
      }
    } catch (error) {
      console.error(error);
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        title: '로그인 실패',
        content: '아이디와 비밀번호를 확인해주세요.',
        reload: false,
      });
    }
  };
  useEffect(() => {
    const showPopup = () => {
      // 현재 시간
      const now = new Date().getTime();

      // 마지막으로 본 시간 가져오기 (localStorage)
      const lastShown = localStorage.getItem('popupLastShown');

      // 24시간(밀리초)
      const twentyFourHours = 24 * 60 * 60 * 1000;

      // 조건: 마지막으로 본 시간이 없거나, 24시간이 지난 경우
      if (!lastShown || now - lastShown > twentyFourHours) {
        // 팝업 표시
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          title: '"유트립과 함께하는 2025년, 새해 복 많이 받으세요"',
          content: `안녕하세요, 유트립 플랫폼 회원 여러분!<br>
<br>
            지난 한 해 동안 유트립 플랫폼과 함께해 주신 회원님들께 진심으로 감사드립니다.<br>
            여러분 덕분에 더 나은 서비스를 위해 꾸준히 성장할 수 있었습니다.<br>
<br>
            2025년에도 유트립은 든든한 파트너로서 여러분의 편리한 업무를 지원하며,<br> 더 나은 서비스를 제공하기 위해 최선을 다하겠습니다.<br>
<br>
            새해 복 많이 받으세요!<br>
            감사합니다.`,
          reload: false,
          imageSrc: '/assets/images/greeting.jpg',
        });
        // 현재 시간을 localStorage에 저장
        localStorage.setItem('popupLastShown', now.toString());
      }
    };
    // showPopup()
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(112deg, rgba(255,252,248,1) 0%, rgba(255,220,185,1) 50%);',
      }}
      component="form"
      role="form"
      onSubmit={handleSubmit((data) => {
        requestLogin(data);
      })}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container width={1200} height={648}>
          <Grid item xs={6} p={20} sx={{ backgroundColor: '#fff' }}>
            <div className="login">
              <Typography
                variant="subtitle1"
                fontSize={32}
                fontWeight={700}
                color="primary"
                sx={{ lineHeight: '1.3125' }}
              >
                Hello,
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <img src="/assets/images/short-logo.png" alt="" width={62} height={37} />
                  <span>RPA Platform</span>
                </Box>
              </Typography>

              <Box mt={6}>
                <TextField
                  name="uid"
                  label="아이디"
                  variant="outlined"
                  placeholder="아이디"
                  value={isLogIn?.loginId}
                  sx={{ width: '100%' }}
                  {...register('uid', {
                    required: '아이디를 입력해주세요.',
                    // pattern: {
                    //   value:
                    //     /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    //   message: '아이디를 다시 확인해주세요.',
                    // },
                  })}
                />
                <Box>
                  {errors.uid && (
                    <Typography variant="caption" color="error" fontWeight="regular">
                      {errors.uid.message}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box mt={2}>
                <TextField
                  name="pw"
                  label="비밀번호"
                  type="password"
                  variant="outlined"
                  placeholder="아이디"
                  sx={{ width: '100%' }}
                  {...register('pw', {
                    required: '비밀번호를 입력해주세요.',
                    // pattern: {
                    //   value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
                    //   message:
                    //     '비밀번호는 영문,숫자,특수문자 를 모두 조합하여 최소 8~15자 입력해 주세요.',
                    // },
                  })}
                />
                <Box>
                  {errors.pw && (
                    <Typography variant="caption" color="error" fontWeight="regular">
                      {errors.pw.message}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberId === true}
                      onClick={() => setRememberId(!rememberId)}
                    />
                  }
                  label="아이디 저장"
                />
              </Box>

              <Box mt={10}>
                {/* <Link href="/lowest-price"> */}
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ width: '100%', color: '#fff', py: 3 }}
                  disabled={isSubmitting}
                >
                  로그인
                </Button>
                {/* </Link> */}
              </Box>

              <Box
                mt={8}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0 10px',
                }}
              >
                <Link
                  href="https://utriprpa.com/bbs/register.php"
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  회원가입
                </Link>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Link
                  href="https://utriprpa.com/bbs/password_lost.php"
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  아이디/비번 찾기
                </Link>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Link
                  href="https://utriprpa.com"
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  유트립 사이트
                </Link>
              </Box>
              <Box mt={6}>
                <Typography variant="subtitle2" style={{ color: '#999', textDecoration: 'none', wordBreak: 'keep-all' }}>
                  회원가입 버튼을 눌러 가입을 완료하면 승인 메일이 발송됩니다. <br />
                  메일을 확인하신 후, 등록한 아이디와 비밀번호로 로그인하여 유트립플랫폼을
                  사용하십시오.
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={6} sx={{ backgroundColor: '#ffb56B', img: { maxWidth: '100%' } }}>
            {imageType === 1 ? (
              <img src="/assets/images/dashboard.png" alt="" />
            ) : (
              <img src="/assets/images/dashboard4.png" alt="" />
            )}
          </Grid>
        </Grid>
      </Box>
      {/* Footer */}
      <Box
        component="footer"
        sx={{
          width: '100%',
          backgroundColor: '#f1f1f1',
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          color: '#666',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Typography variant="body2" color="textSecondary" mr={2}>
            <Link
              href="https://utriprpa.com"
              target="_blank"
              rel="noopener"
              style={{ color: '#333', textDecoration: 'none' }}
            >
              utriprpa.com
            </Link>
            {'    '}
          </Typography>
          <Typography variant="body2" component="span" mr={2}>
            회사명: 트립어스(주) | 대표이사 : 김인중 | 서울 중구 청계천로 40, 한국관광공사 서울센터
            7층 715호 | 사업자등록번호 : 386-87-02335
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography variant="body2" component="span">
            고객센터: 070-4144-8742 | FAX : 0303-3447-8742
          </Typography>
          <Typography variant="body2" component="span">
            | E-mail:{' '}
            <Link href="mailto:utrip@utriprpa.com" sx={{ color: '#666', textDecoration: 'none' }}>
              utrip@utriprpa.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
