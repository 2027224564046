/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';

// ====================== MUI Components  =========================
import {
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Stack,
  Alert,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DragDrop from '../../components/dragDrop'; // dragDrop
// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
// ====================== Images ==================================

function NameListBetaPage() {
  const firstResMultiNameList = sessionStorage.getItem('firstResMultiNameList');
  const responseMultiNameList = sessionStorage.getItem('responseMultiNameList');
  const [controller, dispatch] = useUtripContextApi();
  const [startIndex, setStartIndex] = useState(0);
  const [targetPnrNum, setTargetPnrNum] = useState(1);
  const [fileName, setFileName] = useState('');
  const [radioValue, setRadioValue] = useState('topas');
  const [pnrInfo, setPrnInfo] = useState({});
  const [uploadFile, setUploadFile] = useState();
  const changePnr = (event) => {
    const { name, value } = event.target;
    setPrnInfo({
      ...pnrInfo,
      [name]: value,
    });
  };
  const [response, setResponse] = useState('');

  const clearInfo = () => {
    sessionStorage.removeItem('firstResMultiNameList');
    sessionStorage.removeItem('responseMultiNameList');
    sessionStorage.removeItem('flMultiKey');

    location.reload();
  };
  const convertNewlinesToBreaks = (text) => {
    return text
      ?.replace(/&nbsp;/g, ' ')
      ?.split('<br>')
      ?.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
  };

  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };

  const excuteUpload = () => {
    const formData = new FormData();
    const bodyInfo = {
      pnr1: pnrInfo?.pnrInfo1,
      pnr2: pnrInfo?.pnrInfo2,
      pnr3: pnrInfo?.pnrInfo3,
      startIndex: startIndex,
      targetNum: pnrInfo?.[`pnrInfo${targetPnrNum}`],
    };

    formData.append('fileList', uploadFile);
    formData.append('pnr1', bodyInfo?.pnr1);
    formData.append('pnr2', bodyInfo?.pnr2);
    formData.append('pnr3', bodyInfo?.pnr3);
    formData.append('startIndex', bodyInfo?.startIndex);
    formData.append('targetNum', bodyInfo?.targetNum);

    createUpload(formData);
  };

  const savePnr = (stat) => {
    const body = {
      entry: stat,
      fl_key: sessionStorage.getItem('flKey'),
    };
    saveTopas(body);
  };
  const createUpload = async (body) => {
    setOpenLoading(dispatch, { showYn: true });
    console.log(body)
    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/multinamelistupload', body, {
        timeout: 600000 //600s
      }));
      console.log(response, '업로드 ');
      if (response?.data?.message == 'SUCCESS') {
        sessionStorage.setItem('firstResMultiNameList', JSON.stringify(response.data.pnr_num));
        sessionStorage.setItem('responseMultiNameList', JSON.stringify(response.data.pnr_contents));
        sessionStorage.setItem('flMultiKey', JSON.stringify(response.data.func_log_key));
        setStartIndex(response.data.start_index);
      }
      // else {
      // }
    } catch (error) {
      console.log('Error createUpload:', error);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const saveTopas = async (body) => {
    setOpenLoading(dispatch, { showYn: true });
    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/topasNamelist/manage', body));

      if (response?.data?.status === true) {
        setTargetPnrNum(targetPnrNum + 1);
        if (body.entry == 'IG') {
          sessionStorage.removeItem('firstResMultiNameList');
          sessionStorage.removeItem('responseMultiNameList');
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: `명단 입력이 취소되었습니다.`,
          });
        } else {
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: `${response.data.topas_msg}`,
          });
        }
      }
      //  else {
      //   setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      // }
    } catch (error) {
      console.log('Error saveTopas', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const DownloadLink = styled(Link)(
    ({ theme }) => `
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  `
  );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <DefaultLayout pageTitle="Group Name(Multi)">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                엑셀 양식 다운로드
              </Typography>

              <Box display="flex" gap={3}>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  startIcon={<FileDownloadIcon />}
                >
                  <DownloadLink href="/assets/docs/NAME_LIST_INT.xlsx" download>
                    엑셀 양식 다운로드 (국제)
                  </DownloadLink>
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  startIcon={<FileDownloadIcon />}
                >
                  <DownloadLink href="/assets/docs/NAME_LIST_DOM.xlsx" download>
                    엑셀 양식 다운로드 (국내)
                  </DownloadLink>
                </Button>
              </Box>
            </Box>

            <Box mt={8}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                명단 업로드
              </Typography>

              <RadioGroup row name="type" value={radioValue} onChange={radioChange} sx={{ mb: 3 }}>
                <FormControlLabel value="topas" control={<Radio id="type1" />} label="토파스" />
                {/* <FormControlLabel value="sabre" control={<Radio id="type2" />} label="세이버" /> */}
              </RadioGroup>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" gap={4}>
                    <TextField
                      size="small"
                      placeholder="PNR 입력"
                      name="pnrInfo1"
                      value={pnrInfo?.pnrInfo1}
                      onChange={changePnr}
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      size="small"
                      placeholder="PNR 입력"
                      name="pnrInfo2"
                      value={pnrInfo?.pnrInfo2}
                      onChange={changePnr}
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      size="small"
                      placeholder="PNR 입력"
                      name="pnrInfo3"
                      value={pnrInfo?.pnrInfo3}
                      onChange={changePnr}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box gap={2} display="flex">
                    <TextField
                      value={fileName}
                      size="small"
                      sx={{ flex: '1 1 1%' }}
                      InputProps={{ readOnly: true }}
                      placeholder="선택된 파일 없음"
                    />
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      color="secondary"
                      onChange={inputFileChange}
                    >
                      파일 업로드
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Box>
                  <div className="flex flex-col items-center">
                    <DragDrop onChangeFiles={inputFileChange} description="이곳에 파일을 드래그앤드롭하시면 업로드할 수 있습니다" />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              명단 업로드 결과(Multi)
            </Typography>

            <Typography sx={{ mt: -0.25 }}>
              1.명단 업로드 후 아래의 결과 화면을 확인하고 'PNR 저장' 버튼을 클릭하십시오.<br></br>
              2.단체 PNR은 저장 후 이름 변경이 어렵기 때문에, 사용자가 최종 확인 후 저장할 수 있도록 설계되었습니다.
            </Typography>

            <Alert sx={{ mt: 5 }} severity="info">
              {firstResMultiNameList}
              <br />
              {convertNewlinesToBreaks(responseMultiNameList)}
            </Alert>

            <Stack direction="row" spacing={4} mt={6} justifyContent="center">
              <Button variant="outlined" size="large" sx={{ px: 8 }} onClick={() => savePnr('ER')}>
                PNR 저장 및 확인
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="error"
                sx={{ px: 8 }}
                onClick={() => savePnr('IG')}
              >
                업로드 취소
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          onClick={() => excuteUpload()}
        >
          실행
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ px: 8 }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>

      </Stack>
    </DefaultLayout>
  );
}

export default NameListBetaPage;
