/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';
import * as XLSX from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DragDrop from '../../components/dragDrop'; // dragDrop

// ====================== MUI Components  =========================
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Pagination,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Alert,
  ButtonGroup,
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import Stack from '@mui/material/Stack';

// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
import CustomDialog from '../../components/dialog';
import AlertDialog from '../../components/alert';
import { Category, Flag } from '@mui/icons-material';
import { h } from '@fullcalendar/core/preact.js';
import { set } from 'react-hook-form';
// ====================== Images ==================================

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 6px 6px 0 6px;
  color: ${theme.palette.grey[900]};
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
  resize: none;

  &:hover {
    border-color: ${theme.palette.black};
  }

  &:focus {
    outline: 0;
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 1px ${theme.palette.primary.main};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

function PNRPage() {
  const [controller, dispatch] = useUtripContextApi();
  const [companies, setCompanies] = useState([]);
  const [fromDateF, setFromDateF] = useState(dayjs().format('YYYY-MM-DD'));
  const [toDateF, setToDateF] = useState(dayjs().format('YYYY-MM-DD'));
  const [alertOpen, setAlertOpen] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: fromDateF,
    toDate: toDateF,
    gds: '',
    pnr: '',
    peopleCount: '',
    startAirLine: '',
    startDate: '',
    startLoc: '',
    endLoc: '',
    company: '',
  });

  const [pnrOpen, setPnrOpen] = useState(false);
  const [pnrPopOpen, setPnrPopOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchText, setSearchText] = useState('');
  const [clientsPage, setClientsPage] = useState(1);
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [searchClients, setSearchClients] = useState('');
  const [dateValue, setDateValue] = useState(dayjs(new Date()));
  const [salesInfo, setSalesInfo] = useState([]);
  const [pnrContent, setPnrContent] = useState('');
  const [pnrAddress, setPnrAddress] = useState('');
  const [pnrTextAddress, setPnrTextAddress] = useState('');
  const [savePnrInfo, setSavePnrInfo] = useState({});
  const [saleInfo, setSaleInfo] = useState({});
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [radioValue, setRadioValue] = useState('topas');
  const [distanceCategory, setDistanceCategory] = useState('long');
  const [gsoValue, setGsoValue] = useState('gso');

  const [checkedId, setCheckedId] = useState([]);
  const start = (page - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const salesOnPage = salesInfo?.slice(start, end).map((sale) => sale.id);
  const isAllChecked = salesOnPage.every((id) => checkedId.includes(id));
  const AlertClose = () => {
    setAlertOpen(false);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const [paginatedRows, setPaginatedRows] = useState([]);

  useEffect(() => {
    const filterRows = () => {
      if (!salesInfo) return [];
      return salesInfo.filter((row) =>
        Object.values(row).some((value) =>
          value?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
        )
      );
    };

    const paginateRows = (rows) => {
      const start = (page - 1) * rowsPerPage;
      const end = page * rowsPerPage;
      return rows.slice(start, end);
    };

    const newFilteredRows = filterRows();
    setFilteredRows(newFilteredRows);
    setPaginatedRows(paginateRows(newFilteredRows));
  }, [salesInfo, searchText, page, rowsPerPage]);

  const changeFilter = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const changePage = (event, newPage) => {
    setPage(newPage);
  };
  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const changeSearch = (event) => {
    setSearchText(event.target.value);
    setPage(1);
  };

  const changeClientsPage = (event, newPage) => {
    setClientsPage(newPage);
  };
  const changeClientsPerPage = (event) => {
    setClientsPerPage(parseInt(event.target.value, 10));
    setClientsPage(1);
  };
  const changeSearchClients = (event) => {
    setSearchClients(event.target.value);
    setClientsPage(1);
  };

  const openPnr = (saleInfo) => {
    setSaleInfo(saleInfo);

    saleInfo?.pnr_content == undefined || saleInfo?.pnr_content == ''
      ? getRealTimeContent(saleInfo)
      : getPnrContent(saleInfo?.id);

    setPnrOpen(true);
  };

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const getPnrContent = async (id) => {
    setCheckedId([id]);
    try {
      const response = await utripApi.get(`/apis/func_wire/v2/sales_pnr_contents?id=${id}`);
      if (response.data.message === 'success') {
        setPnrContent(response.data.data);
      }
    } catch (error) {
      console.error('Error getPnrContent : ', error);
    }
  };

  const getRealTimeContent = async (saleInfo) => {
    setOpenLoading(dispatch, { showYn: true });

    let input_data = {};

    if (saleInfo?.gds_type === 'topas') {
      input_data = {
        command: 'RT ' + saleInfo?.pnr_num,
      };
    } else if (saleInfo?.gds_type === 'sabre') {
      input_data = {
        command: '* ' + saleInfo?.pnr_num,
        gds: saleInfo?.gds_type,
      };
    }

    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      command: input_data?.command,
      gds: saleInfo?.gds_type,
    };

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/get_aircraft_command', body));
      if (response.data.message === 'SUCCESS') {
        setPnrContent(response.data.result);
      }
    } catch (error) {
      console.error('error => ', error);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const closePnr = () => setPnrOpen(false);

  const openPnrPop = () => setPnrPopOpen(true);
  const closePnrPop = () => setPnrPopOpen(false);
  const openFilter = () => setFilterOpen(true);
  const resetFilter = () => {
    location.reload();
  };
  const closeFilter = () => {
    setFilterOpen(false);
  };
  const openClient = () => setClientOpen(true);
  const closeClient = () => {
    setClientOpen(false);
  };
  const adjustFilter = () => {
    const newFilteredRows = salesInfo?.filter((row) => {
      return (
        !filter.fromDate ||
        (new Date(row?.created_at?.split('T')[0]) >= new Date(filter.fromDate) &&
          (!filter.toDate || new Date(row.created_at?.split('T')[0]) <= new Date(filter.toDate)) &&
          (!filter.gds || row.gds_type?.toLowerCase().includes(filter.gds.toLowerCase())) &&
          (!filter.pnr || row.pnr_num.trim()?.toLowerCase().includes(filter.pnr.toLowerCase())) &&
          (!filter.peopleCount || row.people_count?.toString().includes(filter.peopleCount)) &&
          (!filter.startAirLine ||
            row.start_air_name?.toLowerCase().includes(filter.startAirLine.toLowerCase())) &&
          (!filter.startDate || new Date(row.tour_start_date) >= new Date(filter.startDate)) &&
          (!filter.startLoc ||
            row.tour_start_loc?.toLowerCase().includes(filter.startLoc.toLowerCase())) &&
          (!filter.endLoc ||
            row.tour_finish_loc?.toLowerCase().includes(filter.endLoc.toLowerCase())) &&
          (!filter.company ||
            row.customer_comapny_name?.toLowerCase().includes(filter.company.toLowerCase())))
      );
    });

    const paginateRows = (rows) => {
      const start = (page - 1) * rowsPerPage;
      const end = page * rowsPerPage;
      return rows.slice(start, end);
    };

    setFilteredRows(newFilteredRows);
    setPaginatedRows(paginateRows(newFilteredRows));
  };

  //엑셀 다운로드
  const excelDownload = () => {
    const XLSX = require('xlsx');

    const processRows = (rows, distanceCategory) => {
      return rows
        .filter(row => row?.gso?.distance_category === distanceCategory)
        .filter((row) => checkedId.includes(row.id))
        .flatMap(row => {
          // 기본 데이터
          const baseData = {
            'id': row.id,
            등록일: row.created_at?.split('T')[0],
            'PNR': row.pnr_num,
            인원: row.people_count,
            출발항공편: row.start_air_name,
            출발일: row.tour_start_date,
            출발지: row.tour_start_loc,
            도착지: row.tour_finish_loc,
            클래스: row.booking_class,
            상태: row.booking_status,
            "항공료_총금액": new Intl.NumberFormat('ko-KR').format(row.gso.price || 0),
            "Deposit_1차": row.first_deposit_date,
            "Deposit_1차(원)": new Intl.NumberFormat('ko-KR').format(row.gso.deposit_fees['1st Deposit'] || 0),
            "Deposit_2차": row.second_deposit_date,
            "Deposit_2차(원)": new Intl.NumberFormat('ko-KR').format(row.gso.deposit_fees['2nd Deposit'] || 0),
            'Penalty_10%': `${row.penalty_10_date} ~ ${formatDate(row.gso.cancellation_fee_dates['D-30'])}`,
            'Penalty_10%(원)': new Intl.NumberFormat('ko-KR').format(row.gso.price * (0.1) || 0),
            'Penalty_30%': `${row.penalty_30_date} ~ ${formatDate(row.gso.cancellation_fee_dates['D-15'])}`,
            'Penalty_30%(원)': new Intl.NumberFormat('ko-KR').format(row.gso.price * (0.3) || 0),
            'Penalty_50%': `${row.penalty_50_date} ~ ${formatDate(row.gso.cancellation_fee_dates['D-0'])}`,
            'Penalty_50%(원)': new Intl.NumberFormat('ko-KR').format(row.gso.price * (0.5) || 0),
            '네임 TL': row.name_tl,
            '발권 TL (OPC)': row.min_tl,
            담당자: row.user_id,
            '고객 회사명': row.customer_comapny_name,
          };

          if (row?.flights?.length > 1) {
            return row.flights.map((flight, index) => {
              // 첫 번째 항공편은 기존 baseData 유지
              if (index === 0) {
                return {
                  ...baseData,
                  출발항공편: flight.flight_number,
                  출발일: flight.date,
                  출발지: flight.dep_apo,
                  도착지: flight.arr_apo,
                  추가정보: '',
                };
              }

              // 두 번째 이후 항공편은 baseData의 모든 필드를 공백 처리
              const emptyBaseData = Object.keys(baseData).reduce((acc, key) => {
                acc[key] = ''; // 모든 필드를 공백으로 설정
                return acc;
              }, {});

              return {
                ...emptyBaseData,
                id: row.id,
                출발항공편: flight.flight_number,
                출발일: flight.date,
                출발지: flight.dep_apo,
                도착지: flight.arr_apo,
                추가정보: '추가 항공편', // 두 번째 이후 항공편만 추가 정보 표시
              };
            });
          }
          return [baseData]; // flights가 없거나 1개면 기본 데이터만 반환
        });
    };

    // 장거리 & 단거리 데이터 생성
    const longDistanceData = processRows(filteredRows, 'long_distance');
    const shortDistanceData = processRows(filteredRows, 'short_distance');

    const longTitleRow = ['GSO PNR LIST(장거리)', '', '', '', '', '', '', '', '', '', '', 'D-90', '', 'D-60', '', 'D-59~30', '', 'D-29~15', '', 'D-14~0', ''];
    const shortTitleRow = ['GSO PNR LIST(단거리)', '', '', '', '', '', '', '', '', '', '', 'D-90', '', 'D-45', '', 'D-44~30', '', 'D-29~15', '', 'D-14~0'];

    // 헤더 가져오기
    const longHeaders = Object.keys(longDistanceData[0] || {});
    const shortHeaders = Object.keys(shortDistanceData[0] || {});

    // 워크시트 생성 및 데이터 추가
    const longSheet = XLSX.utils.aoa_to_sheet([longTitleRow]);
    XLSX.utils.sheet_add_aoa(longSheet, [longHeaders], { origin: "A2" });
    XLSX.utils.sheet_add_json(longSheet, longDistanceData, { origin: "A3", skipHeader: true });

    const shortSheet = XLSX.utils.aoa_to_sheet([shortTitleRow]);
    XLSX.utils.sheet_add_aoa(shortSheet, [shortHeaders], { origin: "A2" });
    XLSX.utils.sheet_add_json(shortSheet, shortDistanceData, { origin: "A3", skipHeader: true });

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, longSheet, "장거리");
    XLSX.utils.book_append_sheet(workBook, shortSheet, "단거리");

    XLSX.writeFile(workBook, "Sales_Data_Export.xlsx");


  };

  const deleteSalesInfo = async () => {
    setOpenLoading(dispatch, { showYn: true });

    const body = {
      delete_list: checkedId.join(','), // Combine checkedId into a comma-separated string
    };

    try {
      const response = await utripApi.delete(
        `/apis/func_wire/v2/sales?delete_list=${body?.delete_list}`
      );
      if (response.data.message === 'success') {
        setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true, reload: true });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true, reload: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const updateSalesInfo = async () => {
    setPnrOpen(false);

    setOpenLoading(dispatch, { showYn: true });
    let body;

    if (saleInfo && checkedId.length < 2) {
      body = {
        gdsType: saleInfo.gds_type,
        sales_id: saleInfo.id,
      };
    } else if (checkedId.length >= 2) {
      body = {
        sales_list: checkedId.join(','),
      };
    }

    try {
      const response = await utripApi.put('/apis/func_wire/v2/sales', body);
      if (response.data.message === 'success') {
        // content 정의
        let content = '';
        if (checkedId.length >= 2) {
          content = `PNR 업데이트 완료 총 ${response.data.data?.total_count}중 ${response.data.data?.updated_count}개 완료`;
        }
        else if (response.data?.data?.includes('단순조회')) {
          content = '단순조회 PNR은 업데이트가 불가합니다.';
        } else {
          content = 'PNR 업데이트 완료';
        }
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content:
            content,
          reload: true,
        });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true, reload: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const updateSalesCompany = async () => {
    setClientOpen(false);

    setOpenLoading(dispatch, { showYn: true });

    const body = {
      customer_company_id: selectedCompanyId,
      sales_list: checkedId,
    };

    try {
      const response = await utripApi.put('/apis/func_wire/v2/sales/company', body);
      if (response.data.message === 'success') {
        setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true, reload: true });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true, reload: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const getSalesInfo = async () => {
    try {
      const response = await utripApi.get('/apis/func_wire/v2/sales/gso');
      if (response.data.message === 'success') {
        const sortedData = response.data.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setSalesInfo(sortedData);
      }
    } catch (error) {
      console.error('Error getSalesInfo : ', error);
    }
  };

  const checkSale = (sale) => {
    setSaleInfo(sale);
    setCheckedId((prevCheckedId) => {
      if (prevCheckedId.includes(sale?.id)) {
        // 기존에 존재하는 saleId 제거
        return prevCheckedId.filter((id) => id !== sale?.id);
      } else {
        // 신규로 saleId 추가
        return [...prevCheckedId, sale?.id];
      }
    });
  };

  const checkAllSales = () => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const salesOnPage = salesInfo.slice(start, end).map((sale) => sale.id);

    setCheckedId((prevCheckedId) => {
      const allChecked = salesOnPage.every((id) => prevCheckedId.includes(id));

      if (allChecked) {
        return prevCheckedId.filter((id) => !salesOnPage.includes(id));
      } else {
        // Add all companies on the current page to checkedId
        const newCheckedIds = new Set(prevCheckedId);
        salesOnPage.forEach((id) => newCheckedIds.add(id));
        return Array.from(newCheckedIds);
      }
    });
  };
  const checkAllLongDistanceSales = () => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const salesOnPage = salesInfo.filter(row => row?.gso?.distance_category === 'long_distance').slice(start, end).map((sale) => sale.id);

    setCheckedId((prevCheckedId) => {
      const allChecked = salesOnPage.every((id) => prevCheckedId.includes(id));

      if (allChecked) {
        return prevCheckedId.filter((id) => !salesOnPage.includes(id));
      } else {
        const newCheckedIds = new Set(prevCheckedId);
        salesOnPage.forEach((id) => newCheckedIds.add(id));
        return Array.from(newCheckedIds);
      }
    });
  };
  const checkAllShortDistanceSales = () => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const salesOnPage = salesInfo.filter(row => row?.gso?.distance_category === 'short_distance').slice(start, end).map((sale) => sale.id);

    setCheckedId((prevCheckedId) => {
      const allChecked = salesOnPage.every((id) => prevCheckedId.includes(id));

      if (allChecked) {
        return prevCheckedId.filter((id) => !salesOnPage.includes(id));
      } else {
        const newCheckedIds = new Set(prevCheckedId);
        salesOnPage.forEach((id) => newCheckedIds.add(id));
        return Array.from(newCheckedIds);
      }
    });
  };

  const getAllCompanyPrfiles = async (empty) => {
    try {
      const response = await utripApi.post('/apis/func_wire/v2/customer-company', empty);
      if (response.data.message === 'success' && response.data.data != null) {
        setCompanies(response.data.data);
      }
    } catch (error) {
      console.error('Error getAllCompanyPrfiles : ', error);
    }
  };

  const clickOneCompany = (company) => {
    setSelectedCompanyId(company?.id);
  };

  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const distanceCategoryChange = (e) => {
    setDistanceCategory(e.target.value);
  };
  const gsoChange = (e) => {
    setGsoValue(e.target.value);
  };

  const changeValue = (event) => {
    const newValue = event.target.value;
    setPnrAddress(newValue);
  };

  const changeTextValue = (event) => {
    const newValue = event.target.value;
    setPnrTextAddress(newValue);
  };

  const celearPnrInfo = () => {
    setPnrAddress('');
    setPnrTextAddress('');
  };

  const savePnrInf = () => {
    if (pnrAddress && pnrAddress.trim() !== '') {
      savePnrAddress();
    } else {
      savePnrTextAddress();
    }
  };

  const savePnrAddress = async () => {
    if (!pnrAddress) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: 'PNR을 입력하세요',
      });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });
    const gso = true ? gsoValue == 'gso' : false
    const body = {
      gds: radioValue,
      pnr_num: pnrAddress,
      gso: gso
    };

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/pnr/data', body));
      if (response.status == '200') {
        setSavePnrInfo(response.data);

        if (response.data?.seg_data.length === 0) {
          setOpenLoading(dispatch, { showYn: false });
          setOpenDialogPopup(dispatch, {
            modalType: 'failCustom',
            showYn: true,
            content: '저장 가능한 유효한 여정이 없습니다.',
          });
          return;
        }

        const res = await dupYn(response.data.pnr);

        if (res && res.data) {
          if (res.data.data === true) {
            setAlertOpen(true);
          } else {
            savePnr(response.data);
          }
        } else {
          console.log('Response from dupYn was not as expected');
        }
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      console.error('error => ', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const savePnrTextAddress = async () => {
    if (!pnrTextAddress) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: 'PNR 내용을 입력하세요',
      });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });
    const gso = true ? gsoValue == 'gso' : false
    const body = {
      pnr_text: pnrTextAddress,
      gso: gso
    };

    try {
      const response = await utripApi.post('/apis/func_wire/v2/pnr/rawdata', body);
      if (response.status == '200') {
        setSavePnrInfo(response.data);
        const res = await dupYn(response.data.pnr);

        if (res && res.data) {
          if (res.data.data === true) {
            setAlertOpen(true);
          } else {
            // [ ]savePnrInfo 값이 변화되지 않음.
            savePnr(response.data);
          }
        } else {
          console.log('Response from dupYn was not as expected');
        }
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      console.error('error => ', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const dupYn = async (pnrInfo) => {
    try {
      const response = await utripApi.get(`/apis/func_wire/v2/sales?pnr=${pnrInfo}`);

      if (response.status == '200') {
        return response;
      }
    } catch (error) {
      console.error('error => ', error);
    }
  };

  const saveAction = () => {
    setAlertOpen(false);
    savePnr();
  };

  const savePnr = async (pnrData) => {
    const body = pnrData || savePnrInfo;
    if (!body) {
      alert('등록실패')
      return
    }
    try {
      const response = await utripApi.post('/apis/func_wire/v2/sales', body);
      if (response.data.message == 'success') {
        location.reload();
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      console.error('error => ', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [fileName, setFileName] = useState('');
  const [uploadFile, setUploadFile] = useState();
  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      console.log(file)
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };
  const formatDate = (dateString) => {
    // "250801" -> "2025-08-01"
    if (!dateString) return "";
    const year = "20" + dateString.slice(0, 2); // "25" -> "2025"
    const month = dateString.slice(2, 4); // "08"
    const day = dateString.slice(4, 6); // "01"
    return `${year}-${month}-${day}`;
  };
  const excuteUpload = async () => {
    setOpenLoading(dispatch, { showYn: true });
    const formData = new FormData();
    formData.append('file', uploadFile);
    try {

      const response = await utripApi.post('/apis/func_wire/v2/ocr_extract_gso', formData
      );
      if (response.status == 200 && response.data.message == 'success') {
        setPnrTextAddress(response.data.ocr_result);
      }
    } catch (error) {
      console.error("Error :", error);
    }
    setOpenLoading(dispatch, { showYn: false });

  }
  useEffect(() => {
    getSalesInfo();
    getAllCompanyPrfiles();
  }, []);

  return (
    <DefaultLayout pageTitle="GSO PNR 대시보드">
      <Box display="flex" justifyContent="space-between" mb={3}>
        <FormControl
          variant="outlined"
          size="small"
          sx={{ textAlign: 'center', flex: '0 0 120px' }}
        >
          <InputLabel>페이지당 노출 개수</InputLabel>
          <Select value={rowsPerPage} onChange={changeRowsPerPage} label="페이지당 노출 개수">
            {[15, 25, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <RadioGroup row name="distance" value={distanceCategory} onChange={distanceCategoryChange}>
          <FormControlLabel value="long" control={<Radio />} label="장거리" />
          <FormControlLabel value="short" control={<Radio />} label="단거리" />
        </RadioGroup>

        <Box display="flex" gap={2}>
          <TextField
            label="검색어 입력"
            variant="outlined"
            value={searchText}
            onChange={changeSearch}
            size="small"
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ fontWeight: 700 }}
            startIcon={<TuneIcon />}
            onClick={openFilter}
          >
            필터
          </Button>
          <Button variant="contained" sx={{ fontWeight: 700, color: 'white' }} onClick={openPnrPop}>
            PNR 등록
          </Button>
        </Box>
      </Box>
      {/* 장거리 */}
      {distanceCategory === 'long' ? (

        <TableContainer component={Paper} className="custom-paper custom-paper-lg table-paper">
          <Table className="custom-table custom-table-sm" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox checked={isAllChecked} onClick={() => checkAllLongDistanceSales()} />
                </TableCell>
                <TableCell align="center">PNR</TableCell>
                <TableCell align="center">등록일</TableCell>
                <TableCell align="center">출발일</TableCell>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">인원</TableCell>
                <TableCell align="center">Deposit 1% 마감일(D-90)</TableCell>
                <TableCell align="center">Deposit 2% 마감일(D-60)</TableCell>
                <TableCell align="center">Penelty 10% 시작(D-59~30)</TableCell>
                <TableCell align="center">Penelty 30% 시작(D-29~15)</TableCell>
                <TableCell align="center">Penelty 50% 적용(D-14~0)</TableCell>
                <TableCell align="center">현재상태</TableCell>
                <TableCell align="center">담당자</TableCell>
                {/* <TableCell align="center">등록정보</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.filter(row => row?.gso?.distance_category === 'long_distance').map((row) => (
                <>
                  <TableRow key={row.id} onClick={() => openPnr(row)}>
                    <TableCell scope="row" align="center">
                      <Checkbox
                        checked={checkedId.includes(row.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          checkSale(row);
                        }} />
                    </TableCell>
                    <TableCell align="center">{row?.pnr_num}</TableCell>
                    <TableCell align="center">{row?.created_at?.split('T')[0]}</TableCell>
                    <TableCell align="center">{row?.tour_start_date}</TableCell>
                    <TableCell align="center">{row?.gso?.distance_category == 'long_distance' ? '장거리' : '단거리'}</TableCell>
                    <TableCell align="center">{row?.people_count}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-90"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-60"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-59"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-29"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-14"])}</TableCell>
                    <TableCell align="center">{row?.booking_status}</TableCell>
                    <TableCell align="center">{row?.user_id}</TableCell>
                    {/* <TableCell align="center">
                      {row?.pnr_content == undefined || row?.pnr_content == '' ? '실시간' : '단순조회'}
                    </TableCell> */}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      ) : (
        <TableContainer component={Paper} className="custom-paper custom-paper-lg table-paper">
          <Table className="custom-table custom-table-sm" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox checked={isAllChecked} onClick={() => checkAllShortDistanceSales()} />
                </TableCell>
                <TableCell align="center">PNR</TableCell>
                <TableCell align="center">등록일</TableCell>
                <TableCell align="center">출발일</TableCell>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">인원</TableCell>
                <TableCell align="center">Deposit 1% 마감일(D-90)</TableCell>
                <TableCell align="center">Deposit 2% 마감일(D-45)</TableCell>
                <TableCell align="center">Penelty 10% 시작(D-44~30)</TableCell>
                <TableCell align="center">Penelty 30% 시작(D-29~15)</TableCell>
                <TableCell align="center">Penelty 50% 적용(D-14~0)</TableCell>
                <TableCell align="center">현재상태</TableCell>
                <TableCell align="center">담당자</TableCell>
                {/* <TableCell align="center">결제상태</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.filter(row => row?.gso?.distance_category === 'short_distance').map((row) => (
                <>
                  <TableRow key={row.id} onClick={() => openPnr(row)}>
                    <TableCell scope="row" align="center">
                      <Checkbox
                        checked={checkedId.includes(row.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          checkSale(row);
                        }} />
                    </TableCell>
                    <TableCell align="center">{row?.pnr_num}</TableCell>
                    <TableCell align="center">{row?.created_at?.split('T')[0]}</TableCell>
                    <TableCell align="center">{row?.tour_start_date}</TableCell>
                    <TableCell align="center">{row?.gso?.distance_category == 'long_distance' ? '장거리' : '단거리'}</TableCell>
                    <TableCell align="center">{row?.people_count}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-90"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-45"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-44"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-29"])}</TableCell>
                    <TableCell align="center">{formatDate(row?.gso?.cancellation_fee_dates?.["D-14"])}</TableCell>
                    <TableCell align="center">{row?.booking_status}</TableCell>
                    <TableCell align="center">{row?.user_id}</TableCell>
                    {/* <TableCell align="center">
                      {row?.pnr_content == undefined || row?.pnr_content == '' ? '실시간' : '단순조회'}
                    </TableCell> */}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}


      <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
        <Pagination
          count={Math.ceil(salesInfo.length / rowsPerPage)}
          page={page}
          onChange={changePage}
          shape="rounded"
          color="primary"
        />
      </Box>

      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => deleteSalesInfo()}
          sx={{ px: 8, color: 'white' }}
        >
          삭제
        </Button>

        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              updateSalesInfo();
            }}
            sx={{ px: 8, color: 'white' }}
          >
            업데이트
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={() => {
              excelDownload();
            }}
            sx={{ px: 8 }}
          >
            Excel 다운로드
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => openClient()}
            sx={{ px: 8 }}
          >
            PNR_거래처연결
          </Button>

        </Box>
      </Stack>

      <CustomDialog
        open={filterOpen}
        onClose={closeFilter}
        title={{ text: '필터 설정', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '설정', handler: adjustFilter, color: 'primary' },
            { label: '초기화', handler: resetFilter, color: 'secondary' },
          ],
        }}
      >
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="등록일 FROM"
              value={dayjs(filter?.fromDate)}
              format="YYYY-MM-DD"
              onChange={(newValue) => {
                const newFromDate = newValue ? newValue.format('YYYY-MM-DD') : null;
                setFromDateF(newFromDate);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  fromDate: newFromDate,
                }));
              }}
              slotProps={{
                textField: { size: 'small', variant: 'filled' },
              }}
            />
            <DatePicker
              label="등록일 TO"
              format="YYYY-MM-DD"
              value={dayjs(filter?.toDate)}
              onChange={(newValue) => {
                const newToDate = newValue ? newValue.format('YYYY-MM-DD') : null;
                setToDateF(newToDate);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  toDate: newToDate,
                }));
              }}
              slotProps={{
                textField: { size: 'small', variant: 'filled' },
              }}
            />
          </LocalizationProvider>
          <TextField
            label="GDS"
            size="small"
            variant="filled"
            name="gds"
            value={filter?.gds}
            onChange={changeFilter}
            placeholder="TOPAS"
          />
          <TextField
            label="PNR"
            size="small"
            variant="filled"
            name="pnr"
            value={filter?.pnr}
            onChange={changeFilter}
            placeholder="5ZP57W"
          />
          <TextField
            label="인원"
            size="small"
            variant="filled"
            name="peopleCount"
            value={filter?.peopleCount}
            onChange={changeFilter}
            placeholder="30"
          />
          <TextField
            label="출발편명"
            size="small"
            variant="filled"
            name="startAirLine"
            value={filter?.startAirLine}
            onChange={changeFilter}
            placeholder="KE017"
          />
          <TextField
            label="출발일자"
            size="small"
            variant="filled"
            name="startDate"
            value={filter?.startDate}
            onChange={changeFilter}
            placeholder="01AUG"
          />
          <TextField
            label="출발지"
            size="small"
            variant="filled"
            name="startLoc"
            value={filter?.startLoc}
            onChange={changeFilter}
            placeholder="ICN"
          />
          <TextField
            label="도착지"
            size="small"
            variant="filled"
            name="endLoc"
            value={filter?.endLoc}
            onChange={changeFilter}
            placeholder="LAX"
          />
          <TextField
            label="거래처"
            size="small"
            variant="filled"
            name="company"
            value={filter?.company}
            onChange={changeFilter}
            placeholder="LAX"
          />
        </Box>
      </CustomDialog>

      <CustomDialog
        open={pnrPopOpen}
        onClose={closePnrPop}
        maxWidth="sm"
        title={{ text: '예약실적등록', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '등록', handler: savePnrInf, color: 'primary' },
            // { label: 'ocr변환', handler: excuteUpload, color: 'primary' },
            { label: '초기화', handler: celearPnrInfo, color: 'secondary' },
          ],
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            PNR Address 직접입력
          </Typography>
          <RadioGroup row name="name" value={radioValue} onChange={radioChange}>
            <FormControlLabel value="topas" control={<Radio />} label="토파스" />
            {/* <FormControlLabel value="sabre" control={<Radio />} label="세이버" /> */}
          </RadioGroup>


          <TextField
            size="small"
            name="pnrAddres"
            placeholder="PNR ADDRESS"
            sx={{ mt: 2 }}
            inputProps={{ maxLength: 6 }}
            value={pnrAddress}
            onChange={changeValue}
          />
        </Box>

        <Box mt={5}>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            PNR Address 직접입력
          </Typography>
          <RadioGroup row name="gsoRadio" value={gsoValue} onChange={gsoChange}>
            {/* <FormControlLabel value="pnr" control={<Radio />} label="일반PNR" /> */}
            <FormControlLabel value="gso" control={<Radio />} label="GSOPNR" />
          </RadioGroup>

          <Box mt={4}>
            <Textarea
              minRows={12}
              sx={{
                width: '100%',
                '&::placeholder': {
                  color: '#C0C0C0',
                  opacity: 0.8, // placeholder 투명도
                },
              }}
              value={pnrTextAddress}
              onChange={changeTextValue}
              placeholder="--- RLR ---&#13;&#10; RP/SELK136BS/SELK136BS            AA/SU  30APR24/0545Z   67ADQ8 &#13;&#10; 1.YOU/TRIPMS &#13;&#10; 2  KE 645 K 01SEP 7 ICNSIN HK1  1840 2340  01SEP  E  KE/67ADQ8 &#13;&#10; 3 AP SEL 070-4144-8742 - TRIPUS - A &#13;&#10; 4 APM 01094160000 &#13;&#10; 5 TK OK30APR/SELK136BS &#13;&#10; 6 SSR CTCM KE HK1 821094160000 &#13;&#10; 7 OPW SELK136BS-02MAY:1600/1C7/KE REQUIRES TICKET ON OR BEFORE &#13;&#10; &nbsp;&nbsp;&nbsp;05MAY:1600 ICN TIME ZONE/TKT/S2 &#13;&#10; 8 OPC SELK136BS-05MAY:1600/1C8/KE CANCELLATION DUE TO NO &#13;&#10; TICKET ICN TIME ZONE/TKT/S2"

            />
          </Box>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={pnrOpen}
        onClose={closePnr}
        maxWidth="sm"
        title={{ text: 'PNR 보기', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closePnr, color: 'secondary' },
            { label: '업데이트', handler: updateSalesInfo, color: 'primary' },
          ],
        }}
      >
        {convertNewlinesToBreaks(pnrContent)}
      </CustomDialog>

      <CustomDialog
        open={clientOpen}
        onClose={closeClient}
        maxWidth="md"
        actions={{
          align: 'center',
          buttons: [{ label: '업데이트', handler: updateSalesCompany, color: 'primary' }],
        }}
      >
        <Box display="flex" gap={2} mb={3}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{ textAlign: 'center', flex: '0 0 120px' }}
          >
            <InputLabel>페이지당 노출 개수</InputLabel>
            <Select
              value={clientsPerPage}
              onChange={changeClientsPerPage}
              label="페이지당 노출 개수"
            >
              {[10, 25, 50, 100].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" gap={2}>
            <TextField
              label="검색어 입력"
              variant="outlined"
              value={searchClients}
              onChange={changeSearchClients}
              size="small"
            />
          </Box>
        </Box>

        <TableContainer sx={{ maxHeight: 450 }}>
          <Table className="custom-table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">{/* <Checkbox defaultChecked /> */}</TableCell>
                <TableCell align="center">거래처</TableCell>
                <TableCell align="center">거래처코드</TableCell>
                <TableCell align="center">담당자</TableCell>
                <TableCell align="center">전화번호</TableCell>
                <TableCell align="center">메모</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((client) => (
                <TableRow key={client.id} onClick={() => clickOneCompany(client)}>
                  <TableCell scope="row" align="center">
                    <Checkbox checked={selectedCompanyId === client.id} />
                  </TableCell>
                  <TableCell align="center">{client?.company_name}</TableCell>
                  <TableCell align="center">{client?.company_code}</TableCell>
                  <TableCell align="center">{client?.manager}</TableCell>
                  <TableCell align="center">{client?.mobile}</TableCell>
                  <TableCell align="center">{client?.content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Pagination
            count={Math.ceil(companies.length / clientsPerPage)}
            page={clientsPage}
            onChange={changeClientsPage}
            shape="rounded"
            color="primary"
          />
        </Box>
      </CustomDialog>
      <AlertDialog
        open={alertOpen}
        handleClose={AlertClose}
        title=""
        save={true}
        content={
          <>
            이미 등록되어있는 PNR이 있습니다.
            {'\n'}
            우선 저장하시고 이후 조회화면에서 정리하시겠습니까?
          </>
        }
        saveAction={saveAction}
      />
    </DefaultLayout>
  );
}

export default PNRPage;
