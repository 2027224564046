/*
  SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
  SPDX-License-Identifier: BSD-3-Clause
*/

import Calendar from './pages/calendar';
import Login from './pages/login';
import CustomCompanyProfiles from './pages/customCompanyProfiles';
import LowestPrice from './pages/lowestPrice';
import Settings from './pages/settings';
import Indrespage from './pages/indResPage';
import IndrespageResult from './pages/indResPage/result';
import LowestPriceDetail from './pages/lowestPrice/detail.js';
import LowestPriceReservation from './pages/lowestPrice/reservation';
import LowestPricingResult from './pages/lowestPrice/result';
import GDSPage from './pages/gds';
import SalesSearch from './pages/salesSearch';
import SalesSearchGSO from './pages/salesSearchGso/index.js';
import NameList from './pages/nameList';
import NameListBeta from './pages/nameListBeta';
import AiBot from './pages/aiBot';
import NoNames from './pages/grpReservation';
import PassportExtract from './pages/passport';
import SsrInternational from './pages/ssrInternational';
import Kemeal from './pages/keMeal';
import KeSeat from './pages/keSeat';
import KeCtcmSkypass from './pages/keCtcmSkypass';
import FileTransform from './pages/fileTransform';

// 퍼블리싱
import PubMain from './pub/main';
import PubPNR from './pub/pnr';
import PubLowestPrice from './pub/lowestPrice';
import PubLogin from './pub/login';
import PubIndvReservation from './pub/indvReservation';
import PubGrpReservation from './pub/grpReservation';
import PubClient from './pub/client';
import PubGDS from './pub/gds';
import PubSettings from './pub/settings';
import PubAiBot from './pub/aiBot';
import PubPassport from './pub/passport';
import PubSsrDomestic from './pub/ssrDomestic';
import PubSsrInternational from './pub/ssrInternational';
import PubListUpload from './pub/listUpload';

const routes = [
  {
    name: 'PNR 관리 캘린더',
    key: 'calendar',
    auth: true,
    route: '/calendar',
    component: <Calendar />,
  },
  {
    name: '로그인',
    key: 'login',
    auth: false,
    route: '/login',
    component: <Login />,
  },
  {
    name: '거래처(담당자) 등록',
    key: 'customCompanyProfiles',
    auth: true,
    route: '/custom-company-profiles',
    component: <CustomCompanyProfiles />,
  },
  {
    name: '여행사/GDS설정',
    key: 'settings',
    auth: true,
    route: '/settings',
    component: <Settings />,
  },
  {
    name: '개인 최저가조회/PNR생성',
    key: 'pricingpage',
    auth: true,
    route: '/pricingpage',
    component: <LowestPrice />,
  },
  {
    name: '여정선택 및 운임보기(최저가 상세)',
    key: 'pricingpageDetail',
    auth: true,
    route: '/pricingpage/:id',
    component: <LowestPriceDetail />,
  },
  {
    name: '최저가 예약',
    key: 'pricingpageReservation',
    auth: true,
    route: '/pricingpage/reservation',
    component: <LowestPriceReservation />,
  },
  {
    name: '최저가 예약 결과',
    key: 'pricingpageReservationResult',
    auth: true,
    route: '/pricingpage/result',
    component: <LowestPricingResult />,
  },
  {
    name: '개인 PNR 생성',
    key: 'indrespage',
    auth: true,
    route: '/indrespage',
    component: <Indrespage />,
  },
  {
    name: '개인예약 결과',
    key: 'indrespageResult',
    auth: true,
    route: '/indrespage/result',
    component: <IndrespageResult />,
  },
  {
    name: 'GDS화면 직접조회',
    key: 'GDS',
    auth: true,
    route: '/inputpage',
    component: <GDSPage />,
  },
  {
    name: 'PNR 등록/리스트 관리',
    key: 'salesSearch',
    auth: true,
    route: '/sales-search',
    component: <SalesSearch />,
  },
  {
    name: 'GSO PNR',
    key: 'salesSearchGso',
    auth: true,
    route: '/sales-search-gso',
    component: <SalesSearchGSO />,
  },
  {
    name: '단체 PNR_명단 업로드',
    key: 'nameList',
    auth: true,
    route: '/nameList',
    component: <NameList />,
  },
  {
    name: '단체 PNR_명단 업로드_멀티',
    key: 'nameListBeta',
    auth: true,
    route: '/nameList-nh',
    component: <NameListBeta />,
  },
  {
    name: 'AI U-BOT',
    key: 'aiBot',
    auth: true,
    route: '/ai-search-user',
    component: <AiBot />,
  },
  {
    name: '단체 PNR 생성',
    key: 'nonameres',
    auth: true,
    route: '/nonameres',
    component: <NoNames />,
  },
  {
    name: '여권변환/APIS연동',
    key: 'passportextract',
    auth: true,
    route: '/passportextract',
    component: <PassportExtract />,
  },
  {
    name: 'SSR 자동입력',
    key: 'ssrInternationalPub',
    auth: true,
    route: '/ssrpage',
    component: <SsrInternational />,
  },
  {
    name: 'KE 기내식 예약',
    key: 'keMeal',
    auth: true,
    route: '/ke-meal',
    component: <Kemeal />,
  },
  {
    name: 'KE 좌석 사전 신청',
    key: 'keSeat',
    auth: true,
    route: '/ke-seat',
    component: <KeSeat />,
  },
  {
    name: 'KE CTCM SKYPASS',
    key: 'keCtcmSkypass',
    auth: true,
    route: '/ke-ctcm-skypass',
    component: <KeCtcmSkypass />,
  },
  {
    name: '국내선 명단파일자동변환',
    key: 'fileTransform',
    auth: true,
    route: '/convert_dom_LCC',
    component: <FileTransform />,
  },
  // 퍼블리싱
  // {
  //   name: '로그인(퍼블)',
  //   key: 'loginPub',
  //   auth: false,
  //   route: '/pub/login',
  //   component: <PubLogin />,
  // },
  // {
  //   name: '여행사 정보설정(퍼블)',
  //   key: 'SettingsPub',
  //   auth: false,
  //   route: '/pub/settings',
  //   component: <PubSettings />,
  // },
  // {
  //   name: '예약캘린더(퍼블)',
  //   key: 'mainPub',
  //   auth: false,
  //   route: '/pub/main',
  //   component: <PubMain />,
  // },
  // {
  //   name: 'PNR 등록관리(퍼블)',
  //   key: 'pnrPub',
  //   auth: false,
  //   route: '/pub/pnr',
  //   component: <PubPNR />,
  // },
  // {
  //   name: '여권정보추출(퍼블)',
  //   key: 'passportPub',
  //   auth: false,
  //   route: '/pub/passport',
  //   component: <PubPassport />,
  // },
  // {
  //   name: 'GDS 직접조회(퍼블)',
  //   key: 'GDSPub',
  //   auth: false,
  //   route: '/pub/gds',
  //   component: <PubGDS />,
  // },
  // {
  //   name: 'AI U-BOT(퍼블)',
  //   key: 'aiBotPub',
  //   auth: false,
  //   route: '/pub/ai-bot',
  //   component: <PubAiBot />,
  // },
  // {
  //   name: '단체명단업로드(퍼블)',
  //   key: 'listUploadPub',
  //   auth: false,
  //   route: '/pub/list-upload',
  //   component: <PubListUpload />,
  // },
  // {
  //   name: 'SSR 자동입력(국제)(퍼블)',
  //   key: 'ssrInternationalPub',
  //   auth: false,
  //   route: '/pub/ssr-international',
  //   component: <PubSsrInternational />,
  // },
  // {
  //   name: 'SSR 자동입력(국내)(퍼블)',
  //   key: 'ssrDomesticPub',
  //   auth: false,
  //   route: '/pub/ssr-domestic',
  //   component: <PubSsrDomestic />,
  // },
  // {
  //   name: '최저가 조회 및 예약(퍼블)',
  //   key: 'lowestPricePub',
  //   auth: false,
  //   route: '/pub/lowest-price',
  //   component: <PubLowestPrice />,
  // },
  // {
  //   name: '개인예약(퍼블)',
  //   key: 'IndvReservationPUb',
  //   auth: false,
  //   route: '/pub/indv-reservation',
  //   component: <PubIndvReservation />,
  // },
  // {
  //   name: '단체예약(퍼블)',
  //   key: 'GrpReservationPUb',
  //   auth: false,
  //   route: '/pub/grp-reservation',
  //   component: <PubGrpReservation />,
  // },
  // {
  //   name: '거래처등록관리(퍼블)',
  //   key: 'ClientPub',
  //   auth: false,
  //   route: '/pub/client',
  //   component: <PubClient />,
  // },
];

export default routes;
