/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import React, { useEffect, useState } from 'react';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';

import {
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';
import DefaultLayout from '../../layouts/defaultLayout';
// ========================
import CustomDialog from '../../components/dialog';


function Settings() {
  const [pnrShareOption, setPnrShareOption] = useState(false);

  const [pageTitle, setPageTitle] = useState(null);
  const [settingInfo, setSettingInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [controller, dispatch] = useUtripContextApi();
  // otp popup first
  const [otpPopOpen, setOtpPopOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const closeOtpPop = () => setOtpPopOpen(false);
  const otpSubmit = async () => {
    setOtpPopOpen(false);
    setOpenLoading(dispatch, { showYn: true });
    const updateTopasInfo = {
      topas_3code: settingInfo?.topas_3code,
      topas_id: settingInfo?.topas_id,
      topas_pw: settingInfo?.topas_pw,
      topas_otp: otp
    };
    try {
      const response = await utripApi.post('/apis/func_wire/v2/otp_submit', updateTopasInfo);
      if (response.data.otp_status === true) {
        try {
          const response = await utripApi.post('/apis/func_wire/v2/topas_setting', updateTopasInfo);
          if (response.data.otp_status === 'on') {
            // OTP submit2로 이동
            setOtpSecondPopOpen(true)
          }
          else if (response.data.otp_status === true) {
            getSettingInfo();
            setOpenDialogPopup(dispatch, {
              modalType: 'sucCustom',
              showYn: true,
              content: '로그인 성공 : OTP 인증 계정입니다. Q방 번호, 단말기 상태가 최신화 되었습니다.',
            });
          } else {
            setOpenDialogPopup(dispatch, {
              modalType: 'failCustom',
              showYn: true,
              content: '로그인 실패 : ID, 비밀번호, otp 번호를 다시 확인해주세요.',
            });
          }
        } catch (error) {
          setOpenDialogPopup(dispatch, {
            modalType: 'failCustom',
            showYn: true,
            content: '로그인 실패 : ID, 비밀번호, otp 번호를 다시 확인해주세요.',
          });
          console.error(error.response);
        } finally {
          setOpenLoading(dispatch, { showYn: false });
        }
      }
    } catch {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: 'OTP 전송을 실패하였습니다. ID, 비밀번호, OTP 번호를 다시 확인해주세요',
      });
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  }
  const changeOtpValue = (event) => {
    const newValue = event.target.value;
    setOtpSecond(newValue);
  };
  // otp popup second
  const [otpSecondPopOpen, setOtpSecondPopOpen] = useState(false);
  const [otpSecond, setOtpSecond] = useState('');
  const closeOtpSecondPop = () => setOtpSecondPopOpen(false);
  const otpSecondSubmit = async () => {
    setOtpSecondPopOpen(false);
    setOpenLoading(dispatch, { showYn: true });
    const updateTopasInfo = {
      topas_3code: settingInfo?.topas_3code,
      topas_id: settingInfo?.topas_id,
      topas_pw: settingInfo?.topas_pw,
      topas_otp: otpSecond
    };
    try {
      const response = await utripApi.post('/apis/func_wire/v2/otp_submit_2', updateTopasInfo);
      if (response.data.otp_status === true) {
        getSettingInfo();
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: '로그인 성공 : OTP 인증 계정입니다. Q방 번호, 단말기 상태가 최신화 되었습니다.',
        });
      }
    } catch {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: 'OTP 전송을 실패하였습니다. ID, 비밀번호, OTP 번호를 다시 확인해주세요',
      });
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  }
  const changeValue = (event) => {
    const newValue = event.target.value;
    setOtp(newValue);
  };
  const checkboxChange = (event) => {
    const { name, checked } = event.target;
    setUserInfo({
      ...userInfo,
      [name]: checked,
    });
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const handleSettingInfo = (event) => {
    const { name, value } = event.target;
    setSettingInfo({
      ...settingInfo,
      [name]: value,
    });
  };

  const updateUserInfo = async (userInfo) => {
    setOpenLoading(dispatch, { showYn: true });

    const updateUserInfo = {
      personal_mail: userInfo?.personal_mail,
      tra_num: userInfo?.tra_num,
      u_hp: userInfo?.u_hp,
      u_name: userInfo?.u_name,
      pnr_share_option: userInfo?.pnr_share_option,
    };

    try {
      const response = await utripApi.post('/apis/func_wire/v2/save_agency', updateUserInfo);
      if (response.status === 200) {
        getSettingInfo();
        setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const updateTopasInfo = async (topasInfo) => {
    setOpenLoading(dispatch, { showYn: true });

    const updateTopasInfo = {
      topas_3code: topasInfo?.topas_3code,
      topas_id: topasInfo?.topas_id,
      topas_pw: topasInfo?.topas_pw,
      timeBasedOtp: topasInfo?.timeBasedOtp,
    };
    if (!updateTopasInfo.topas_id || !updateTopasInfo.topas_pw) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '토파스 아이디 또는 비밀번호가 존재하지 않습니다.',
      });
      setOpenLoading(dispatch, { showYn: false });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/topas_setting', updateTopasInfo));
      if (response?.data?.otp_status === 'on') {
        // TODO OTP Submit 추가
        // OTP창이 필요한 경우
        setOtpPopOpen(true)
      }
      else if (response?.data?.otp_status === true) {
        getSettingInfo();
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: '로그인 성공 : OTP 인증 계정입니다. Q방 번호, 단말기 상태가 최신화 되었습니다.',
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '로그인 실패 : ID, 비밀번호, otp 번호를 다시 확인해주세요.',
      });
      console.error(error);
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  };

  const updateSabreInfo = async (sabreInfo) => {
    setOpenLoading(dispatch, { showYn: true });

    const updateSabreInfo = {
      sabre_3code: sabreInfo?.sabre_3code,
      sabre_id: sabreInfo?.sabre_id,
      sabre_pcc: sabreInfo?.sabre_pcc,
      sabre_pw: sabreInfo?.sabre_pw,
    };

    if (!updateSabreInfo.sabre_id || !updateSabreInfo.sabre_pcc || !updateSabreInfo.sabre_pw) {
      setOpenLoading(dispatch, { showYn: false });
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '세이버 아이디, 비밀번호 또는 PCC가 존재하지 않습니다.',
      });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await utripApi.post('/apis/func_wire/v2/sabre_setting', updateSabreInfo);
      if (response.data.sabre_login_response === 'success') {
        getSettingInfo();
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: `sabre 인증 완료, TL관리용 Q ${response.data.sabre_q}`,
        });
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenLoading(dispatch, { showYn: false });
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      console.error(error.response);
    } finally {
      setOpenLoading(dispatch, { showYn: false });

    }
  };

  const getSettingInfo = async (empty) => {
    try {
      const response = await utripApi.get('/apis/func_wire/v2/travel-agency', empty);
      if (response.data.message === 'success') {
        //사용자 정보
        setUserInfo(response.data.data);
        //gds 정보
        setSettingInfo(response.data.gds);

        //사용자 정보
        setValue('personal_mail', response.data.gds?.personal_mail);
        setValue('u_name', response.data.gds?.u_name);
        setValue('u_hp', response.data.gds?.u_hp);
        setValue('tra_num', response.data.gds?.tra_num);
        setValue('pnr_share_option', response.data.data?.pnr_share_option);

        //세이버
        setValue('sabre_id', response.data.gds?.sabre_id);
        setValue('sabre_pcc', response.data.gds?.sabre_pcc);
        setValue('sabre_q', response.data.gds?.sabre_q);
        setValue('sabre_status', response.data.gds?.sabre_status);
        setValue('sabre_3code', response.data.gds?.sabre_3code);

        //토파스
        setValue('topas_id', response.data.gds?.topas_id);
        setValue('topas_3code', response.data.gds?.topas_3code);
        setValue('topas_q', response.data.gds?.topas_q);
        setValue('topas_status', response.data.gds?.topas_status);
      }
    } catch (error) {
      console.error('Error getAllCompanyPrfiles : ', error);
    } finally {
      setOpenLoading(dispatch, { showYn: false });

    }
  };

  useEffect(() => {
    getSettingInfo();
  }, []);

  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid container spacing={5} alignItems="center" justifyContent="space-between">
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          component="form"
          role="form"
          onSubmit={handleSubmit((data) => {
            updateUserInfo(data);
          })}
        >
          <Paper className="custom-paper" sx={{ display: 'flex', flexDirection: 'column', height: { xs: '55vh !important', sm: '55vh !important ', md: '55vh !important' } }}>
            <Box sx={{ flex: '1 1 1%' }}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                사용자 정보
              </Typography>

              <TextField
                label="담당자명"
                variant="outlined"
                size="small"
                disabled
                value={userInfo?.userName + ' (' + userInfo?.userEmail + ')' || ''}
                sx={{ width: '100%', mt: 3 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="소속여행사"
                variant="outlined"
                size="small"
                disabled
                value={userInfo?.agencyNm || ''}
                sx={{ width: '100%', mt: 5 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="여행사 전화번호"
                variant="outlined"
                size="small"
                {...register('tra_num')}
                onChange={handleSettingInfo}
                value={settingInfo.tra_num || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="담당자 영문명"
                variant="outlined"
                size="small"
                {...register('u_name')}
                onChange={handleSettingInfo}
                value={settingInfo.u_name || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="담당자 전화번호"
                variant="outlined"
                size="small"
                {...register('u_hp')}
                onChange={handleSettingInfo}
                value={settingInfo.u_hp || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="담당자 E-MAIL"
                variant="outlined"
                size="small"
                {...register('personal_mail')}
                onChange={handleSettingInfo}
                value={settingInfo.personal_mail || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <Box mt={2}>
                <FormControlLabel
                  className="activated-checkbox"
                  control={
                    <Checkbox
                      name="pnr_share_option"
                      {...register('pnr_share_option')}
                      checked={userInfo?.pnr_share_option === true}
                      onClick={(e) => {
                        e.stopPropagation();
                        checkboxChange(e);
                      }}
                    />
                  }
                  label="PNR_SHARE_OPTION"
                />
              </Box>
            </Box>

            <Stack direction="row" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 8, color: 'white' }}
                type="submit"
                disabled={isSubmitting}
              >
                저장
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          component="form"
          role="form"
          onSubmit={handleSubmit((data) => {
            updateTopasInfo(data);
          })}
        >
          <Paper className="custom-paper" sx={{ display: 'flex', flexDirection: 'column', height: { xs: '55vh !important', sm: '55vh !important ', md: '55vh !important' } }}>
            <Box sx={{ flex: '1 1 1%' }}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                GDS설정_토파스
              </Typography>

              <TextField
                label="UID"
                variant="outlined"
                size="small"
                {...register('topas_id')}
                onChange={handleSettingInfo}
                value={settingInfo.topas_id || ''}
                sx={{ width: '100%', mt: 3 }}
              />
              <TextField
                label="비밀번호"
                type="password"
                variant="outlined"
                size="small"
                {...register('topas_pw')}
                onChange={handleSettingInfo}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="여행사 3코드 (선택사항)"
                variant="outlined"
                size="small"
                {...register('topas_3code')}
                onChange={handleSettingInfo}
                value={settingInfo.topas_3code || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="상태"
                variant="outlined"
                size="small"
                disabled
                value={settingInfo.topas_status == 'Y' ? '인증완료' : '인증 미완료' || ''}
                sx={{ width: '100%', mt: 5 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Q방 번호"
                variant="outlined"
                size="small"
                disabled
                value={settingInfo.topas_q || ''}
                sx={{ width: '100%', mt: 5 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Topas_OTP"
                variant="outlined"
                size="small"
                {...register('timeBasedOtp')}
                onChange={handleSettingInfo}
                value={settingInfo.timeBasedOtp || ''}
                sx={{ width: '100%', mt: 3 }}
              />
            </Box>
            <Stack direction="row" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 8, color: 'white' }}
                type="submit"
                disabled={isSubmitting}
              >
                저장
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          component="form"
          role="form"
          onSubmit={handleSubmit((data) => {
            updateSabreInfo(data);
          })}
        >
          <Paper className="custom-paper" sx={{ display: 'flex', flexDirection: 'column', height: { xs: '55vh !important', sm: '55vh !important ', md: '55vh !important' } }}>
            <Box sx={{ flex: '1 1 1%' }}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                GDS설정_세이버
              </Typography>

              <TextField
                label="아이디"
                variant="outlined"
                size="small"
                {...register('sabre_id')}
                onChange={handleSettingInfo}
                value={settingInfo.sabre_id || ''}
                sx={{ width: '100%', mt: 3 }}
              />
              <TextField
                label="비밀번호"
                type="password"
                variant="outlined"
                size="small"
                {...register('sabre_pw')}
                onChange={handleSettingInfo}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="여행사 PCC"
                variant="outlined"
                size="small"
                {...register('sabre_pcc')}
                onChange={handleSettingInfo}
                value={settingInfo.sabre_pcc || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="여행사 3코드 (선택사항)"
                variant="outlined"
                size="small"
                {...register('sabre_3code')}
                onChange={handleSettingInfo}
                value={settingInfo.sabre_3code || ''}
                sx={{ width: '100%', mt: 5 }}
              />
              <TextField
                label="상태"
                variant="outlined"
                size="small"
                disabled
                value={settingInfo.sabre_status == 'Y' ? '인증완료' : '인증 미완료' || ''}
                sx={{ width: '100%', mt: 5 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Q방 번호"
                variant="outlined"
                size="small"
                disabled
                value={settingInfo.sabre_q || ''}
                sx={{ width: '100%', mt: 5 }}
                InputProps={{ readOnly: true }}
              />
            </Box>

            <Stack direction="row" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 8, color: 'white' }}
                type="submit"
                disabled={isSubmitting}
              >
                저장
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Alert severity="success" sx={{
        fontSize: '1rem', // 원하는 글자 크기 설정
        lineHeight: '1.5',  // 줄 간격 조정 (선택 사항)
      }}>
        본 페이지에서 토파스와 세이버 GDS 설정을 완료하시면, 각 GDS 단말기와 연동되어 Utrip 플랫폼의 모든 기능을 효과적으로 활용하실 수 있습니다.<br />
        - 토파스 단말기 설정: UID와 비밀번호를 입력하고 저장 버튼을 누르면 최초 1회 OTP 인증을 진행하게 됩니다. Amadeus로부터 받은 인증번호를 입력하고 저장해주세요.<br />
        - 세이버 단말기 설정: 아이디, 비밀번호, PCC를 입력하고 저장해주세요.<br />
        GDS 비밀번호나 OTP를 변경하신 경우, 본 페이지에서 반드시 정보를 업데이트하여 연동 상태를 유지해 주시기 바랍니다.<br />
        <Typography
          component="span"
          sx={{ color: "blue !important" }} // 색상과 스타일 지정
        >
          GDS 설정을 완료하지 않으면 GDS 연동 기능을 사용할 수 없으며, Utrip 플랫폼의 일부 기능만 사용 가능하니 참고하시기 바랍니다.

        </Typography>
      </Alert>
      <CustomDialog
        open={otpPopOpen}
        onClose={closeOtpPop}
        maxWidth="sm"
        title={{ text: 'OTP', align: 'center' }}
      // actions={{
      //   align: 'center',
      //   buttons: [
      //     { label: '전송', handler: otpSubmit, color: 'primary' },
      //   ],
      // }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            Sellconect Google OTP를 설정 후 다시 시도해주세요.
          </Typography>

          {/* <TextField
            size="medium"
            name="pnrAddres"
            placeholder="OTP"
            sx={{ mt: 2 }}
            value={otp}
            onChange={changeValue}
          /> */}
        </Box>
      </CustomDialog>
      <CustomDialog
        open={otpSecondPopOpen}
        onClose={closeOtpSecondPop}
        maxWidth="sm"
        title={{ text: 'OTP', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '전송', handler: otpSecondSubmit, color: 'primary' },
          ],
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            Sellconect 두번째 OTP 입력
          </Typography>

          <TextField
            size="medium"
            name="otpSecond"
            placeholder="OTP"
            sx={{ mt: 2 }}
            value={otpSecond}
            onChange={changeOtpValue}
          />
        </Box>
      </CustomDialog>
    </DefaultLayout>
  );
}

export default Settings;
