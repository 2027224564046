/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';

import { Box, Button, Paper, Stack, TextField, Typography, Link } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import DragDrop from '../../components/dragDrop'; // dragDrop
function ExcelWrite({ goToExcelResult }) {
  const [controller, dispatch] = useUtripContextApi();

  const [fileName, setFileName] = useState('');
  const [uploadFile, setUploadFile] = useState({});
  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };
  const uploadNanameRes = () => {
    const formData = new FormData();
    formData.append('file', uploadFile);
    nonameRes(formData);
  };

  const nonameRes = async (body) => {
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/nonameres', body));

      console.log(response, '결과');
      if (response.status === 200 && response.data != 'error') {
        sessionStorage.setItem('nonameResultGroup', JSON.stringify(response?.data));
        goToExcelResult();
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data}`,
      });
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const clearInfo = () => {
    location.reload();
    sessionStorage.removeItem('nonameResultGroup');
  };
  const DownloadLink = styled(Link)(
    ({ theme }) => `
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  `
  );

  return (
    <>
      <Paper className="custom-paper" sx={{ position: 'relative' }}>
        <Typography variant="h5" fontWeight={700} textAlign="center" gutterBottom>
          노네임단체 PNR생성(시리즈)
        </Typography>

        <Button
          variant="contained"
          size="small"
          color="grey"
          startIcon={<FileDownloadIcon />}
          sx={{ position: 'absolute', top: 24, right: 24 }}
        >
          <DownloadLink href="/assets/docs/G_noname reservation.xlsx" download>
            양식다운로드
          </DownloadLink>
        </Button>

        <Box mt={10} gap={2} display="flex">
          <TextField
            value={fileName}
            size="small"
            sx={{ flex: '1 1 1%' }}
            InputProps={{ readOnly: true }}
            placeholder="선택된 파일 없음"
          />
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            color="secondary"
            onChange={inputFileChange}
          >
            파일 업로드
            <VisuallyHiddenInput type="file" />
          </Button>
        </Box>
        <div className="flex flex-col items-center">
          <DragDrop onChangeFiles={inputFileChange} description="이곳에 파일을 드래그앤드롭하시면 업로드할 수 있습니다" />
        </div>
        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            sx={{ px: 8, color: 'white' }}
            onClick={() => uploadNanameRes()}
          >
            실행
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{ px: 8 }}
            onClick={() => clearInfo()}
          >
            초기화
          </Button>
        </Stack>
      </Paper>
    </>
  );
}

export default ExcelWrite;
