/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState, useRef } from 'react';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenDialogPopup, useUtripContextApi, setOpenLoading } from '../../context';

// ====================== MUI Components  =========================
import {
  Grid,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Button,
} from '@mui/material';

// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';

// ====================== Images ==================================

function GDSPage() {
  const [controller, dispatch] = useUtripContextApi();
  const paperRef = useRef(null);
  const inputRef = useRef(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [radioValue, setRadioValue] = useState('topas');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [input, setInput] = useState('');
  const [requests, setRequests] = useState([]);
  const [responses, setResponses] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);  // 명령어 탐색용 인덱스

  useEffect(() => {
    const savedRequests = JSON.parse(sessionStorage.getItem('gdsReq')) || [];
    const savedResponses = JSON.parse(sessionStorage.getItem('gdsRes')) || [];
    const gdsRadio = sessionStorage.getItem('gdsRadioValue') || 'topas';

    setRequests(savedRequests);
    setResponses(savedResponses);
    setRadioValue(gdsRadio);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('gdsReq', JSON.stringify(requests));
    sessionStorage.setItem('gdsRes', JSON.stringify(responses));
  }, [requests, responses]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const clearInfo = () => {
    sessionStorage.removeItem('gdsReq');
    sessionStorage.removeItem('gdsRes');
    sessionStorage.removeItem('gdsRadioValue');
    location.reload();
  };
  const topasConnect = async () => {
    // Forbidden 
    setOpenLoading(dispatch, { showYn: true });
    setIsSubmitting(true);
    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      command: 'IG',
      gds: radioValue,
    };

    try {
      const response = await utripApi.post('/apis/func_wire/v2/topas_connect', body);
      if (response.data.message === 'SUCCESS') {
        console.log(response)
        // setResponses((prevResponses) => [...prevResponses, response.data.result]);
      }
    } catch (error) {
      // setResponses((prevResponses) => [...prevResponses, 'REQUESTED DISPLAY NOT AVAILABLE']);
    }
    setIsSubmitting(false);
    setOpenLoading(dispatch, { showYn: false });
  }
  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  // 키 이벤트 핸들러
  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      setOpenLoading(dispatch, { showYn: true });
      setIsSubmitting(true);
      const request = input;
      setRequests((prevRequests) => [...prevRequests, request]);

      const body = {
        apikey: 'R26RWLMTV8IVTF3F',
        command: request,
        gds: radioValue,
      };

      try {
        const response = await customCatch(utripApi.post('/apis/func_wire/v2/get_aircraft_command', body));
        if (response.data.message === 'SUCCESS') {
          setResponses((prevResponses) => [...prevResponses, response.data.result]);
        }
      } catch (error) {
        setResponses((prevResponses) => [...prevResponses, 'REQUESTED DISPLAY NOT AVAILABLE ,상단 Topas OTP 입력을 해주세요.']);
      }

      setInput('');  // 입력 필드 비우기
      setHistoryIndex(-1);  // 새로운 명령어가 입력되면 인덱스 리셋
      setIsSubmitting(false);
      setOpenLoading(dispatch, { showYn: false });
    }
    else if (event.key === 'ArrowUp') {
      // 이전 명령어 탐색 (위 방향키)
      if (historyIndex === -1 && requests.length > 0) {
        setHistoryIndex(requests.length - 1);
        setInput(requests[requests.length - 1]);
      } else if (historyIndex > 0) {
        setHistoryIndex(historyIndex - 1);
        setInput(requests[historyIndex - 1]);
      }
    }
    else if (event.key === 'ArrowDown') {
      // 다음 명령어 탐색 (아래 방향키)
      if (historyIndex !== -1 && historyIndex < requests.length - 1) {
        setHistoryIndex(historyIndex + 1);
        setInput(requests[historyIndex + 1]);
      } else if (historyIndex === requests.length - 1) {
        setHistoryIndex(-1);
        setInput('');
      }
    }
  };

  // 토파스, 세이버 라디오 변경
  const radioChange = (e) => {
    setRadioValue(e.target.value);
    setRequests([]);
    setResponses([]);

    sessionStorage.removeItem('gdsReq');
    sessionStorage.removeItem('gdsRes');
  };

  useEffect(() => {
    if (paperRef.current) {
      paperRef.current.scrollTop = paperRef.current.scrollHeight;
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [responses]);

  const handleButtonClick = (text) => {
    setInput((prev) => prev + text);
  };
  return (
    <>
      <DefaultLayout pageTitle={pageTitle}>
        <Grid container spacing={5}>
          <Grid item xs={10}>
            <Grid container spacing={5} mb={5} alignItems="center">
              <Grid item display="flex" alignItems="center" gap={5}>
                <Typography variant="subtitle2" fontWeight={700}>
                  GDS 선택
                </Typography>

                <RadioGroup row name="type" value={radioValue} onChange={radioChange} >
                  <FormControlLabel value="topas" control={<Radio id="type1" />} label="토파스" />
                  <FormControlLabel value="sabre" control={<Radio id="type2" />} label="세이버" />
                </RadioGroup>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => clearInfo()}>
                  화면 Clear
                </Button>
              </Grid>
              {/* 
              
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => topasConnect()}>
                  토파스 재접속
                </Button>
              </Grid>
              */}
            </Grid>
            <Paper>
              <Box>
                <Box>
                  <TextField
                    className="gds-input"
                    variant="standard"
                    placeholder="지시어를 입력하세요."
                    onChange={handleInputChange}
                    value={input || ''}
                    disabled={isSubmitting}
                    onKeyDown={handleKeyDown}
                    inputRef={inputRef}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </Paper>
            <br></br>
            <Paper
              className="custom-paper"
              sx={{ height: '74vh !important', maxHeight: '74vh !important' }}
              ref={paperRef}
            >
              <Box>
                <Box>
                  {requests?.map((req, index) => (
                    <React.Fragment key={index}>
                      <Box
                        sx={{ fontFamily: 'Consolas, monospace', whiteSpace: 'pre-wrap' }}
                        className="gds-request"
                      >
                        {req}
                      </Box>
                      <Box
                        sx={{ fontFamily: 'Consolas, monospace', whiteSpace: 'pre-wrap' }}
                        className="gds-answer"
                      >
                        {convertNewlinesToBreaks(responses[index])}
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={2} sx={{ mt: 14 }}>
            <Box
              p={4}
              color="white"
              sx={{
                fontWeight: 700,
                bgcolor: 'secondary.main',
                textAlign: 'center',
                borderRadius: '4px 4px 0 0',
              }}
            >
              세이버 Key
            </Box>
            <Paper
              className="custom-paper"
              sx={{
                height: '76vh !important',
                maxHeight: '76vh !important',
                borderRadius: '0 0 4px 4px',
              }}
            >
              <Grid className="flex flex-col justify-center items-center w-full" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <Button color="secondary" onClick={() => handleButtonClick("@")}>
                  @
                </Button>
              </Grid>
              <Grid className="flex flex-col justify-center items-center w-full" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>

                <Button color="secondary" onClick={() => handleButtonClick("§")} >
                  §
                </Button>
              </Grid>
              <Grid className="flex flex-col justify-center items-center w-full" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>

                <Button color="secondary" onClick={() => handleButtonClick("¶")}>
                  ¶
                </Button>
              </Grid>
              <Grid className="flex flex-col justify-center items-center w-full" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <Button color="secondary" onClick={() => handleButtonClick("¥")}>
                  ¥
                </Button>
              </Grid>
              <Grid className="flex flex-col justify-center items-center w-full" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <Button color="secondary" onClick={() => handleButtonClick("¤")}>
                  ¤
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DefaultLayout>
    </>
  );
}

export default GDSPage;
