/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import utripApi from '../utils/api/utripApi';
import { isLogIn, logoutRemoveAuthInfo } from '../utils/session';
import { useUtripContextApi, setOpenLoading, setOpenDialogPopup } from '../context';
// ====================== MUI Components  =========================
import List from '@mui/material/List';
import Button from '@mui/material/Button';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { Box, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// ====================== Service Components ======================
import CustomDialog from './dialog';

// ====================== Images ==================================
import {
  WhiteIconAddress,
  WhiteIconCalendar,
  WhiteIconFace,
  IconLaptop,
  WhiteIconRobot,
  IconUserPen,
} from './icons';
import { set } from 'react-hook-form';

const Listbox = styled('ul')(
  ({ theme }) => `
    padding: 10px;
  `
);

const requestLogout = async () => {
  try {
    const body = {
      access_token: isLogIn?.token,
    };
    const response = await utripApi.post('/apis/func_wire/v2/logout', body);

    if (response.data.message === 'SUCCESS') {
      logoutRemoveAuthInfo();
    }
  } catch (error) {
    logoutRemoveAuthInfo();
    console.error(error);
  }
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  cursor: default;
  user-select: none;
  text-align: center;
  border-radius: 10px;
  
  a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 8px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    background-color: ${theme.palette.primary.main};
    color: #fff;
    
    a,
    .MuiButton-root {
      color: #fff;
      transition: none;
    }
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.grey[400]};
  }
  `
);

function Toolbar() {
  const [controller, dispatch] = useUtripContextApi();

  const [fileName, setFileName] = useState('');
  const [uploadFile, setUploadFile] = useState({});
  const [helpMsg, setHelpMsg] = useState('');
  const [topasOtp, setTopasOtp] = useState('');
  const [otpPop, setOtpPop] = useState('');

  const changeValue = (event) => {
    const newValue = event.target.value;
    setHelpMsg(newValue);
  };

  const openHelpPop = () => {
    setHelpPop(true);
  };

  const [helpPop, setHelpPop] = useState('');
  const closeHelpPop = () => {
    setHelpPop(false);
  };
  // OTP 팝업추가
  const topasOtpSubmit = async () => {
    setOtpPop(false);
    setOpenLoading(dispatch, { showYn: true });
    const updateTopasInfo = {
      topas_otp: `${topasOtp}`
    };
    try {
      const response = await utripApi.post('/apis/func_wire/v2/topas_otp_submit', updateTopasInfo);
      // TODO Response 처리
      //  [ ] 로그인 OTP처리
      setTopasOtp('')
      console.log(response);
      if (response.data.message === 'SUCCESS' || response.data.otp_status === 'SUCCESS') {
        if (response.data.otp_status == true) {
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: 'OTP 전송이 완료 되었습니다. 기능을 다시 동작하세요.',
          });
        } else if (response.data.otp_status == false) {
          setOpenDialogPopup(dispatch, {
            modalType: 'failCustom',
            showYn: true,
            content: 'OTP 전송을 실패하였습니다. ID, 비밀번호, OTP 번호를 다시 확인해주세요.'
          });
        }
      }

    } catch {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: 'OTP 전송을 실패하였습니다. ID, 비밀번호, OTP 번호를 다시 확인해주세요.',
      });
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  }
  const changeOtpValue = (event) => {
    const newValue = event.target.value;
    setTopasOtp(newValue);
  };

  const openOtpPop = () => {
    setOtpPop(true);
  };


  const closeOtpPop = () => {
    setOtpPop(false);
  };
  const inputFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };

  const sendHelp = async () => {
    setOpenLoading(dispatch, { showYn: true });
    setHelpPop(false);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('helpmsg', helpMsg);
    const body = formData;

    try {
      const response = await utripApi.post('/apis/sendQA', body);

      if (response.data.message === 'success') {
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: '문의내용이 유트립담당자에게 전송되었습니다.<br>확인후 빠르게 답변드리겠습니다.',
        });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div className="toolbar">
      <List component="div" className="toolbar-shortcuts">
        {/* <ListItem>
          <List component="div" className="toolbar-info">
            <Dropdown>
              <MenuButton>
                <img src="/assets/images/combination_white_logo.svg" class="logo-full" alt="Logo" style={{ "max-width": "100 %", "height": "10px" }} />
              </MenuButton>
              <Menu className="toolbar-info-context">
                <MenuItem>
                  <Link to="/ke-meal">기내식 사전 신청</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/ke-seat">좌석 사전 신청</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/ke-ctcm-skypass">CTCM, SKYPASS</Link>
                </MenuItem>
              </Menu>
            </Dropdown>
          </List>
        </ListItem> */}
        {/* <ListItem component={Link} to="/calendar">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <WhiteIconCalendar sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="캘린더" />
        </ListItem>
        <ListItem component={Link} to="/sales-search">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <WhiteIconAddress sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="PNR 관리" />
        </ListItem>
        <ListItem component={Link} to="/passportextract">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <WhiteIconFace sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="여권추출" />
        </ListItem> */}
        {/* <ListItem component={Link} to="/inputpage">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <IconLaptop sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="GDS 직접조회" />
        </ListItem>
        <ListItem component={Link} to="/pub/ssr-international">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <IconUserPen sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="SSR 자동입력(국제)" />
        </ListItem> */}
        {/* <ListItem component={Link} to="/ai-search-user">
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <WhiteIconRobot sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="U-BOT" />
        </ListItem> */}

        <ListItem component={Link} onClick={() => openHelpPop()}>
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <QuestionMarkIcon sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="문의하기" />
        </ListItem>
        <ListItem component={Link} onClick={() => openOtpPop()}>
          <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
            <IconLaptop sx={{ width: 20, height: 20, color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Topas OTP 입력하기" />
        </ListItem>
      </List>
      <List component="div" className="toolbar-shortcuts">
        <ListItem >
          <ListItemText primary={isLogIn.loginId} />
        </ListItem>
        <ListItem >
          <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemText primary="여행사/GDS설정" />
          </Link>
        </ListItem>
        <ListItem component={Link} onClick={() => requestLogout()}>
          <ListItemText primary="로그아웃" />
        </ListItem>
        {/* <List component="div" className="toolbar-info">
          <Dropdown>
            <MenuButton>{isLogIn.loginId}</MenuButton>
            <Menu className="toolbar-info-context">
              <MenuItem>
                <Link to="/settings">여행사설정</Link>
              </MenuItem>
              <MenuItem>
                <Button
                  sx={{ bgColor: 'transparent', color: '#000', textAlign: 'center', width: '100%' }}
                  onClick={() => requestLogout()}
                >
                  로그아웃
                </Button>
              </MenuItem>
            </Menu>
          </Dropdown>
        </List> */}
      </List>

      <CustomDialog
        open={helpPop}
        onClose={closeHelpPop}
        maxWidth="md"
        title={{ text: '문의하기', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '전송', handler: sendHelp, color: 'primary' },
            { label: '닫기', handler: closeHelpPop, color: 'secondary' },
          ],
        }}
      >
        <Box>
          <Box mt={1}>
            <TextField
              label="문의사항"
              value={helpMsg}
              onChange={changeValue}
              multiline
              sx={{ width: '100%' }}
              placeholder="작업 중 어려움이 있으시면 화면 캡쳐와 함께 내용을 작성하여 즉시 보내주시기 바랍니다."
            />
          </Box>
          <Box mt={1} gap={2} display="flex">
            <TextField
              label="파일 선택"
              value={fileName}
              size="small"
              sx={{ flex: '1 1 1%' }}
              InputProps={{ readOnly: true }}
              placeholder="선택된 파일 없음"
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              color="secondary"
              onChange={inputFileChange}
            >
              파일 업로드
              <VisuallyHiddenInput type="file" />
            </Button>
          </Box>
        </Box>
      </CustomDialog>
      <CustomDialog
        open={otpPop}
        onClose={closeOtpPop}
        maxWidth="sm"
        title={{ text: 'OTP', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '전송', handler: topasOtpSubmit, color: 'primary' },
          ],
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            Sellconect Google OTP입력
          </Typography>

          <TextField
            size="medium"
            name="pnrAddres"
            placeholder="OTP"
            sx={{ mt: 2 }}
            value={topasOtp}
            onChange={changeOtpValue}
          />
        </Box>
      </CustomDialog>
    </div>
  );
}

export default Toolbar;
