import { useState } from 'react';
import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// --- Components
import Nav from './nav';

// --- Custom Styles
const BoxHeader = styled(Box)(
  ({ theme }) => css`
    &.collapse {
      width: 68px;

      .css-10g1rhp-MuiListItemText-root {
        display: none;
      }

      .logo-symbol {
        display: block;
      }

      .logo-full {
        display: none;
      }

      .profile {
        padding: 0;
        width: 40px;
        background: none;

        .thumb {
          width: 40px;
          height: 40px;
        }

        .MuiSvgIcon-fontSizeMedium,
        .MuiTypography-subtitle1,
        .MuiTypography-body2 {
          display: none;
        }
      }

      .drawer-top {
        flex-direction: column;
      }
    }

    .logo-symbol {
      display: none;
    }

    .btn-drawer-toggle {
      position: relative;
      min-width: 24px;
      width: 24px;
      height: 16px;
      padding: 0;

      .arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        transition: opacity 0.15s ease-in-out;
        opacity: 0;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 7px;
          height: 2px;
          border-radius: 4px;
          background-color: black;
          position: absolute;
          left: 3px;
        }

        &::before {
          top: 0;
          transform-origin: top right;
          transform: rotate(-35deg);
        }

        &::after {
          bottom: 0;
          transform-origin: bottom right;
          transform: rotate(35deg);
        }
      }

      .bar {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        height: 2px;
        background-color: black;
        border-radius: 4px;
        transition: width 0.15s ease-in-out;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: black;
        border-radius: 4px;
        transition: width 0.15s ease-in-out;
      }

      &::before {
        top: 0;
        transform-origin: top left;
      }

      &::after {
        bottom: 0;
        transform-origin: bottom left;
      }

      &.collapse,
      &:hover {
        .bar,
        &::before,
        &::after {
          width: calc(100% - 10px);
        }

        .arrow {
          opacity: 1;
        }
      }

      &.collapse {
        .arrow::before,
        .arrow::after {
          left: inherit;
          right: 0;
        }

        .arrow::before {
          transform-origin: top left;
          transform: rotate(35deg);
        }

        .arrow::after {
          transform-origin: bottom left;
          transform: rotate(-35deg);
        }
      }
    }
  `
);

const Drawer = styled(MuiDrawer)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: 20px 20px 40px 20px;
    max-height: 200vh;

    .drawer-top {
      display: flex;
      align-items: center;
      // gap: 1.5em 1em;
      margin-bottom: 1.5em;
    }

    & .MuiDrawer-paper {
      position: relative;
      z-index: 1;
      background-color: white;
      border: 0;
    }

    & ~ .profile {
      display: block;
      padding: 20px 16px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      background-color: rgba(255, 255, 255, 0.2);
    }

    & ~ .profile .thumb-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & ~ .profile .thumb {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      overflow: hidden;
    }

    & ~ .profile .thumb img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `
);

function Header() {
  const [isCollapse, setIsCollapse] = useState(false);

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <BoxHeader
      component="header"
      className={isCollapse ? 'collapse' : ''}
      width={270}
      sx={{
        position: 'relative',
        p: 0,
        bgcolor: 'white',
      }}
    >
      <Drawer variant="permanent" open={true}>
        <Box className="drawer-top">
          {/* <Button
            className={isCollapse ? 'btn-drawer-toggle collapse' : 'btn-drawer-toggle'}
            onClick={toggleCollapse}
          >
            <span className="bar"></span>
            <span className="arrow"></span>
          </Button> */}
          <Box className="logo">
            <Link
              href="/calendar"
              sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}
            >
              <img
                src="/assets/images/symbol.svg"
                className="logo-symbol"
                alt="Symbol"
                style={{ width: 29 }}
              />
              <img
                src="/assets/images/Utrip_KoreanAir_Color_logo_3.png"
                className="logo-full"
                alt="Logo"
                style={{ maxWidth: '100%', height: '55px' }}
              />
            </Link>
          </Box>
        </Box>

        <Nav />
      </Drawer>
    </BoxHeader>
  );
}

export default Header;
