/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import DragDrop from '../../components/dragDrop'; // dragDrop
// ====================== MUI Components  =========================
import {
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Stack,
  Alert,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';
// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
// ====================== Images ==================================

function NameListPage() {
  const [controller, dispatch] = useUtripContextApi();

  const [fileName, setFileName] = useState('');
  const [uploadFile, setUploadFile] = useState();
  const [radioValue, setRadioValue] = useState('topas');
  const [pnrInfo, setPrnInfo] = useState('');
  const changePnr = (event) => {
    setPrnInfo(event.target.value);
  };

  const firstResNameList = sessionStorage.getItem('firstResNameList');
  const responseNameList = sessionStorage.getItem('responseNameList');

  const convertNewlinesToBreaks = (text) => {
    return text
      ?.replace(/&nbsp;/g, ' ')
      ?.split('<br>')
      ?.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
  };
  //엑셀 다운로드
  const excelDownload = () => {
    const excelData = {};

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'PNR 등록 데이터');
    XLSX.writeFile(workBook, 'Sales_Data_Export.xlsx');
  };

  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };

  const excuteUpload = () => {
    const formData = new FormData();
    // const qnaInfo = JSON.stringify(data);
    const blob = new Blob([pnrInfo], { type: 'application/json' });
    formData.append('fileList', uploadFile);
    formData.append('pnrList', pnrInfo);
    if (radioValue === 'sabre') {
      uploadSabre(formData);
    } else {
      uploadTopas(formData);
    }
  };

  const savePnr = (stat) => {
    const body = {
      entry: stat,
      fl_key: sessionStorage.getItem('flKey'),
    };

    if (radioValue === 'sabre') {
      saveSabre(body);
    } else {
      saveTopas(body);
    }
  };

  // 명단업로드 API - sabre : /apis/func_wire/sabrenamelistupload
  // 명단업로드 이후 API - sabre :/apis/func_wire/v2/sabreNamelist/manage

  const uploadSabre = async (body) => {
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/sabrenamelistupload', body, {
        timeout: 600000 //600s
      }));
      if (response?.status === 200 && response?.data.includes('error')) {
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: response.data,
        });
      } else if (response?.status === 200) {
        sessionStorage.setItem('firstResNameList', JSON.stringify(response.data[0]));
        sessionStorage.setItem('responseNameList', JSON.stringify(response.data[1]));
        sessionStorage.setItem('flKey', JSON.stringify(response.data[2]));
      }
    } catch (error) {
      console.log('Error uploadSabre', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const saveSabre = async (body) => {
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/sabreNamelist/manage', body));
      if (response?.data === true) {
        if (body?.entry == 'IG') {
          sessionStorage.removeItem('firstResNameList');
          sessionStorage.removeItem('responseNameList');
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: `명단 입력이 취소되었습니다.`,
            reload: true,
          });
        } else {
          setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true });
        }
      }
      // else {
      //   setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      // }
    } catch (error) {
      console.log('Error saveSabre', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  // 명단업로드 API - topas : /apis/func_wire/namelistupload
  // 명단업로드 이후 API - topas :/apis/func_wire/v2/topasNamelist/manage

  const uploadTopas = async (body) => {
    setOpenLoading(dispatch, { showYn: true });
    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/namelistupload', body, {
        timeout: 600000 //600s
      }));
      if (response?.status === 200 && response?.data.includes('error')) {
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: response.data,
        });
      } else if (response?.status === 200) {
        sessionStorage.setItem('firstResNameList', JSON.stringify(response.data[0][0]));
        sessionStorage.setItem('responseNameList', JSON.stringify(response.data[0][1]));
        sessionStorage.setItem('flKey', JSON.stringify(response.data[0][2]));
      }
    } catch (error) {
      console.log('Error uploadTopas:', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const saveTopas = async (body) => {
    setOpenLoading(dispatch, { showYn: true });
    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/topasNamelist/manage', body));

      if (response?.data?.status === true) {
        if (body?.entry == 'IG') {
          sessionStorage.removeItem('firstResNameList');
          sessionStorage.removeItem('responseNameList');
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: `명단 입력이 취소되었습니다.`,
            reload: true,
          });
        } else {
          setOpenDialogPopup(dispatch, {
            modalType: 'sucCustom',
            showYn: true,
            content: `${response.data.topas_msg}`,
            reload: true,
          });
        }
      }
      // else {
      //   setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      // }
    } catch (error) {
      console.log('Error saveTopas', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const DownloadLink = styled(Link)(
    ({ theme }) => `
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  `
  );

  const clearInfo = () => {
    sessionStorage.removeItem('firstResNameList');
    sessionStorage.removeItem('responseNameList');

    location.reload();
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <DefaultLayout pageTitle='Group Name (Single)'>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                엑셀 양식 다운로드
              </Typography>

              <Box display="flex" gap={3}>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  startIcon={<FileDownloadIcon />}
                >
                  <DownloadLink href="/assets/docs/NAME_LIST_INT.xlsx" download>
                    엑셀 양식 다운로드 (국제)
                  </DownloadLink>
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  startIcon={<FileDownloadIcon />}
                >
                  <DownloadLink href="/assets/docs/NAME_LIST_DOM.xlsx" download>
                    엑셀 양식 다운로드 (국내)
                  </DownloadLink>
                </Button>
              </Box>
            </Box>

            <Box mt={8}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                명단 업로드
              </Typography>

              <RadioGroup row name="type" value={radioValue} onChange={radioChange} sx={{ mb: 3 }}>
                <FormControlLabel value="topas" control={<Radio id="type1" />} label="토파스" />
                <FormControlLabel value="sabre" control={<Radio id="type2" />} label="세이버" />
              </RadioGroup>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" gap={4}>
                    <TextField
                      size="small"
                      placeholder="PNR 입력"
                      value={pnrInfo}
                      onChange={changePnr}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box gap={2} display="flex">
                    {/* <TextField
                      value={fileName}
                      size="small"
                      sx={{ flex: '1 1 1%' }}
                      InputProps={{ readOnly: true }}
                      placeholder="선택된 파일 없음"
                    /> */}
                    <DragDrop onChangeFiles={inputFileChange} description={fileName.length ? fileName : "파일을 드래그앤드롭 하시면 업로드할 수 있습니다"} />

                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      color="secondary"
                      onChange={inputFileChange}
                    >
                      파일 업로드
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Box>
                  {/* <div className="flex flex-col items-center">
                    <DragDrop onChangeFiles={inputFileChange} description={fileName.length ? fileName : "이곳에 파일을 드래그앤드롭 하시면 업로드할 수 있습니다"} />
                  </div> */}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              명단 업로드 결과(Single)
            </Typography>

            {/* <Alert severity="warning" sx={{ mt: 3 }}> */}
            <Typography sx={{ mt: -0.25 }}>
              1.명단 업로드 후 아래의 결과 화면을 확인하고 'PNR 저장' 버튼을 클릭하십시오.<br></br>
              2.단체 PNR은 저장 후 이름 변경이 어렵기 때문에, 사용자가 최종 확인 후 저장할 수 있도록 설계되었습니다.
            </Typography>
            {/* </Alert> */}

            <Alert sx={{ mt: 5 }} severity="info">
              {firstResNameList}
              <br />
              {convertNewlinesToBreaks(responseNameList)}
            </Alert>

            <Stack direction="row" spacing={4} mt={6} justifyContent="center">
              <Button variant="contained"
                size="large"
                sx={{ px: 8, color: 'white' }} onClick={() => savePnr('ER')}>
                PNR 저장
              </Button>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ px: 8, color: 'white' }}
                onClick={() => savePnr('IG')}
              >
                업로드 취소
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          onClick={() => excuteUpload()}
        >
          실행
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ px: 8 }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>

      </Stack>
    </DefaultLayout>
  );
}

export default NameListPage;
