/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState } from 'react';
import utripApi, { customCatch } from '../../utils/api/utripApi';
import { setOpenDialogPopup, useUtripContextApi, setOpenLoading } from '../../context';
import DragDrop from '../../components/dragDrop'; // dragDrop
// ====================== MUI Components  =========================
import {
  Paper,
  Grid,
  Box,
  Alert,
  Checkbox,
  Chip,
  TextField,
  Button,
  Link,
  Stack,
  FormGroup,
  FormControlLabel,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import { styled } from '@mui/material/styles';

// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
// ====================== Images ==================================

function SsrInternational() {
  const [controller, dispatch] = useUtripContextApi();

  const [uploadFile, setUploadFile] = useState();
  const [fileName, setFileName] = useState('');
  const [lastName, setLastName] = useState(sessionStorage.getItem('ssr_lastName') || 'A');
  const [firstName, setFirstName] = useState(sessionStorage.getItem('ssr_firstName') || 'B');
  const [gender, setGender] = useState(sessionStorage.getItem('ssr_gender') || 'C');
  const [birth, setBirth] = useState(sessionStorage.getItem('ssr_birth') || 'D');
  const [national, setNational] = useState(sessionStorage.getItem('ssr_national') || 'E');
  const [passportNo, setPassportNo] = useState(sessionStorage.getItem('ssr_passportNo') || 'F');
  const [passportExp, setPassportExp] = useState(sessionStorage.getItem('ssr_passportExp') || 'G');
  const [authority, setAuthority] = useState(sessionStorage.getItem('ssr_authority') || 'H');
  const [ctcm, setCtcm] = useState(sessionStorage.getItem('ssr_ctcm') || 'I');
  const [ctce, setCtce] = useState(sessionStorage.getItem('ssr_ctce') || 'J');
  const [skypass, setSkypass] = useState(sessionStorage.getItem('ssr_skypass') || 'K');
  const [radioValue, setRadioValue] = useState('topas');
  const [pnrNumbers, setPnrNumbers] = useState(JSON.parse(sessionStorage.getItem('ssr_pnrNumbers')) || Array(12).fill(''));

  const handlePnrChange = (index, value) => {
    const updatedPnrs = [...pnrNumbers];
    updatedPnrs[index] = value;
    sessionStorage.setItem(`ssr_pnrNumbers`, JSON.stringify(updatedPnrs))
    setPnrNumbers(updatedPnrs);
  };
  //결과
  const [pnrResult, setPnrResult] = useState({ pnr_result: '', summary_df: [], unmatch_df: [] });

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  // test 데이터
  const fees = {
    items: [
      {
        no: 1,
        ptc: '성인',
        count: 2,
        fee: '60,000',
        tax: '71,800',
        price: 'KRW 131,800',
        basis: 'IKV',
      },
      {
        no: 2,
        ptc: '소아',
        count: 1,
        fee: '60,000',
        tax: '71,800',
        price: 'KRW 131,800',
        basis: 'IKV',
      },
    ],
    total: {
      count: 3,
      fee: '60,000',
      tax: '71,800',
      price: 'KRW 131,800',
    },
  };

  const [checkedValues, setCheckedValues] = useState({
    apis: true,
    apisDom: false,
    ctcm: false,
    ctce: false,
    skypass: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedValues((prevState) => {
      if (name === 'apis' && checked) {
        console.log(checked)
        return {
          ...prevState,
          [name]: checked,
          'apisDom': false, // 'apis-dom' 체크 해제
        };
      } else if (name === 'apisDom' && checked) {
        console.log(checked)
        return {
          ...prevState,
          [name]: checked,
          'apis': false,
        };
      }
      return {
        ...prevState,
        [name]: checked
      }
    }
    );

    if (name === 'apis' && checked) {
      setLastName(lastName);
      setFirstName(firstName);
      setGender(gender);
      setBirth(birth);
      setNational(national);
      setPassportNo(passportNo);
      setPassportExp(passportExp);
      setAuthority(authority);
    }

    if (name === 'apisDom' && checked) {
      setLastName(lastName);
      setFirstName(firstName);
      setGender(gender);
      setBirth(birth);
      setNational(national);
    }

    if (name === 'ctcm' && checked) {
      setCtcm(ctcm);
    }

    if (name === 'ctce' && checked) {
      setCtce(ctce);
    }

    if (name === 'skypass' && checked) {
      setSkypass(skypass);
    }
  };

  const excelColumns = [];
  const charA = 'A'.charCodeAt(0);
  const charZ = 'Z'.charCodeAt(0);

  for (let i = charA; i <= charZ; i++) {
    excelColumns.push(String.fromCharCode(i));
  }

  for (let i = charA; i <= charZ; i++) {
    for (let j = charA; j <= charZ; j++) {
      excelColumns.push(String.fromCharCode(i) + String.fromCharCode(j));
    }
  }

  const selectChange = (event) => {
    const name = event.target.name;
    sessionStorage.setItem(`ssr_${name}`, event.target.value)
    switch (name) {
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'gender':
        setGender(event.target.value);
        break;
      case 'birth':
        setBirth(event.target.value);
        break;
      case 'national':
        setNational(event.target.value);
        break;
      case 'passportNo':
        setPassportNo(event.target.value);
        break;
      case 'passportExp':
        setPassportExp(event.target.value);
        break;
      case 'authority':
        setAuthority(event.target.value);
        break;
      case 'ctce':
        setCtce(event.target.value);
        break;
      case 'ctcm':
        setCtcm(event.target.value);
        break;
      case 'skypass':
        setSkypass(event.target.value);
        break;
      default:
        break;
    }
  };

  // 토파스, 세이버 라디오 변경
  const radioChange = (e) => {
    setRadioValue(e.target.value);

    if (e.target.value === 'sabre') {
      setCheckedValues((prevValues) => ({
        ...prevValues,
        ctce: false,
        skypass: false,
        apisDom: false,
      }));
    }
  };

  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const excuteSsrApi = () => {
    if (radioValue == 'topas') {
      callTopasApi();
    } else {
      callSabreApi();
    }
  };

  const callSabreApi = async () => {
    setOpenLoading(dispatch, { showYn: true });
    const formData = new FormData();

    formData.append('ex-first_name', firstName);
    formData.append('ex-last_name', lastName);
    formData.append('ex-nationality', national);
    formData.append('ex-birth', birth);
    formData.append('ex-sex', gender);
    formData.append('ex-passport_expiry', checkedValues.apis ? passportExp : '');
    formData.append('ex-passport_num', checkedValues.apis ? passportNo : '');
    formData.append('ex-country_code',checkedValues.apis ? authority : '');
    formData.append('ex-ctce', checkedValues.ctce ? ctce : '');
    formData.append('ex-ctcm', checkedValues.ctcm ? ctcm : '');
    formData.append('ex-skypass', checkedValues.skypass ? skypass : '');
    formData.append('file', uploadFile);



    const filteredPnrNumbers = pnrNumbers.filter((pnr) => pnr.trim() !== '');
    formData.append('pnr_numbers', JSON.stringify(filteredPnrNumbers));

    try {
      const response = await utripApi.post('/apis/ssr_upload/excel_match_sabre', formData, {
        timeout: 600000 //600s
      });

      if (response.status === 200) {
        setPnrResult(response.data);
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      console.error(error.response);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const callTopasApi = async () => {
    if (!uploadFile) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `파일을 업로드 해주세요.`,
      });
      return;
    }

    setOpenLoading(dispatch, { showYn: true });

    const formData = new FormData();
    formData.append('ex-last_name', lastName);
    formData.append('ex-first_name', firstName);
    formData.append('ex-sex', gender);
    formData.append('ex-birth', birth);
    formData.append('ex-nationality', national);
    formData.append('ex-passport_expiry', checkedValues.apis ? passportExp : '');
    formData.append('ex-passport_num', checkedValues.apis ? passportNo : '');
    formData.append('ex-country_code', checkedValues.apis ? authority : '');
    formData.append('ex-ctce', checkedValues.ctce ? ctce : '');
    formData.append('ex-ctcm', checkedValues.ctcm ? ctcm : '');
    formData.append('ex-skypass', checkedValues.skypass ? skypass : '');
    formData.append('file', uploadFile);

    const filteredPnrNumbers = pnrNumbers.filter((pnr) => pnr.trim() !== '');
    formData.append('pnr_numbers', JSON.stringify(filteredPnrNumbers));

    try {
      const response = await customCatch(utripApi.post('/apis/ssr_upload/excel_match_topas', formData, {
        timeout: 600000 //600s
      }));

      if (response.status === 200) {
        setPnrResult(response.data);
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      console.error(error.response);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const clearInfo = () => {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.startsWith("ssr_")) {
        sessionStorage.removeItem(key);
        i--;
      }
    }
    location.reload();
  };

  return (
    <DefaultLayout pageTitle="APIS,CTCM,SKYPASS,CTCE">
      <Paper className="custom-paper-full-height">
        <Box>
          <Alert
            icon={
              <Chip
                icon={<LooksOneOutlinedIcon />}
                label="STEP"
                sx={{
                  fontWeight: 700,
                  color: 'info.dark',
                  gap: 0.5,
                  px: 3,
                  flexDirection: 'row-reverse',
                  '.MuiChip-label': { p: 0 },
                  '.MuiChip-icon': { color: 'info.dark', fontSize: 16, m: 0 },
                }}
              />
            }
            severity="info"
            sx={{ fontWeight: 700, py: 1, px: 2, alignItems: 'center' }}
          >
            SSR 정보가 담긴 excel 파일을 upload 해 주세요.
          </Alert>

          <Box mt={3}>
            <Box mt={2} gap={2} display="flex">
              <TextField
                value={fileName}
                size="small"
                sx={{ flex: '1 1 1%' }}
                InputProps={{ readOnly: true }}
                placeholder="선택된 파일 없음"
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                color="secondary"
                onChange={inputFileChange}
              >
                파일 업로드
                <VisuallyHiddenInput type="file" />
              </Button>
              <Button variant="outlined" color="secondary">
                <Link
                  href="/assets/docs/APIS_sample_excel.xlsx"
                  sx={{ textDecoration: 'none', color: 'secondary.main' }}
                >
                  샘플 양식 다운로드
                </Link>
              </Button>
            </Box>
          </Box>
          <div className="flex flex-col items-right">
            <DragDrop onChangeFiles={inputFileChange} description="이곳에 파일을 드래그앤드롭하시면 업로드할 수 있습니다" />
          </div>
        </Box>

        <Box mt={5}>
          <Alert
            icon={
              <Chip
                icon={<LooksTwoOutlinedIcon />}
                label="STEP"
                sx={{
                  fontWeight: 700,
                  color: 'info.dark',
                  gap: 0.5,
                  px: 3,
                  flexDirection: 'row-reverse',
                  '.MuiChip-label': { p: 0 },
                  '.MuiChip-icon': { color: 'info.dark', fontSize: 16, m: 0 },
                }}
              />
            }
            severity="info"
            sx={{ fontWeight: 700, py: 1, px: 2, alignItems: 'center' }}
          >
            엑셀파일에 SSR 정보가 포함되어 있는 열(column)을 mapping 해 주세요.
          </Alert>

          <Box mt={3}>
            <Box>
              <RadioGroup row name="type" value={radioValue} onChange={radioChange}>
                <FormControlLabel value="topas" control={<Radio id="type1" />} label="토파스" />
                <FormControlLabel value="sabre" control={<Radio id="type2" />} label="세이버" />
              </RadioGroup>
            </Box>
            <Box mt={1}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="apis"
                      checked={checkedValues.apis}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="APIS(국제)"
                  sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
                />
                {radioValue !== 'sabre' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="apisDom"
                        checked={checkedValues.apisDom}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="APIS(국내)"
                    sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="ctcm"
                      checked={checkedValues.ctcm}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="CTCM"
                  sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
                />
                {radioValue !== 'sabre' && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="ctce"
                          checked={checkedValues.ctce}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="CTCE"
                      sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="skypass"
                          checked={checkedValues.skypass}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SKYPASS"
                      sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
                    />
                  </>
                )}
              </FormGroup>
            </Box>
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              예약자 정보
            </Typography>
            <TableContainer>
              <Table className="custom-table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormControl fullWidth size="small">
                        <InputLabel>성</InputLabel>
                        <Select
                          labelId="lastNameLabel"
                          id="lastName"
                          value={lastName}
                          name="lastName"
                          label="lastName"
                          onChange={selectChange}
                        >
                          {excelColumns.map((column) => (
                            <MenuItem key={column} value={column}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth size="small">
                        <InputLabel>이름</InputLabel>
                        <Select
                          labelId="firstNameLabel"
                          id="firstName"
                          value={firstName}
                          name="firstName"
                          label="firstName"
                          onChange={selectChange}
                        >
                          {excelColumns.map((column) => (
                            <MenuItem key={column} value={column}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {(checkedValues.apis || checkedValues.apisDom) && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>성별</InputLabel>
                          <Select
                            labelId="genderLabel"
                            id="gender"
                            value={gender}
                            name="gender"
                            label="gender"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    {(checkedValues.apis || checkedValues.apisDom) && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>생년월일</InputLabel>
                          <Select
                            labelId="birthLabel"
                            id="birth"
                            value={birth}
                            name="birth"
                            label="birth"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    {(checkedValues.apis || checkedValues.apisDom) && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>국적</InputLabel>
                          <Select
                            labelId="nationalLabel"
                            id="national"
                            value={national}
                            name="national"
                            label="national"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              SSR 정보
            </Typography>
            <TableContainer>
              <Table className="custom-table">
                <TableBody>
                  <TableRow>
                    {checkedValues.apis && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>여권번호</InputLabel>
                          <Select
                            labelId="passportNoLabel"
                            id="passportNo"
                            value={passportNo}
                            name="passportNo"
                            label="passportNo"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    {checkedValues.apis && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>여권만료일</InputLabel>
                          <Select
                            labelId="passportExpLabel"
                            id="passportExp"
                            value={passportExp}
                            name="passportExp"
                            label="passportExp"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    {checkedValues.apis && (
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel>발행국가</InputLabel>
                          <Select
                            labelId="authorityLabel"
                            id="authority"
                            value={authority}
                            name="authority"
                            label="authority"
                            onChange={selectChange}
                          >
                            {excelColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    <>
                      {checkedValues.ctcm && (
                        <TableCell>
                          <FormControl fullWidth size="small">
                            <InputLabel>CTCM</InputLabel>
                            <Select
                              labelId="ctcmLabel"
                              id="ctcm"
                              value={ctcm}
                              name="ctcm"
                              label="ctcm"
                              onChange={selectChange}
                            >
                              {excelColumns.map((column) => (
                                <MenuItem key={column} value={column}>
                                  {column}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                      {checkedValues.ctce && (
                        <TableCell>
                          <FormControl fullWidth size="small">
                            <InputLabel>CTCE</InputLabel>
                            <Select
                              labelId="ctceLabel"
                              id="ctce"
                              value={ctce}
                              name="ctce"
                              label="ctce"
                              onChange={selectChange}
                            >
                              {excelColumns.map((column) => (
                                <MenuItem key={column} value={column}>
                                  {column}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                      {checkedValues.skypass && (
                        <TableCell>
                          <FormControl fullWidth size="small">
                            <InputLabel>SKYPASS</InputLabel>
                            <Select
                              labelId="skypassLabel"
                              id="skypass"
                              value={skypass}
                              name="skypass"
                              label="skypass"
                              onChange={selectChange}
                            >
                              {excelColumns.map((column) => (
                                <MenuItem key={column} value={column}>
                                  {column}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                    </>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box mt={5}>
          <Alert
            icon={
              <Chip
                icon={<Looks3OutlinedIcon />}
                label="STEP"
                sx={{
                  fontWeight: 700,
                  color: 'info.dark',
                  gap: 0.5,
                  px: 3,
                  flexDirection: 'row-reverse',
                  '.MuiChip-label': { p: 0 },
                  '.MuiChip-icon': { color: 'info.dark', fontSize: 16, m: 0 },
                }}
              />
            }
            severity="info"
            sx={{ fontWeight: 700, py: 1, px: 2, alignItems: 'center' }}
          >
            SSR을 반영할 PNR을 입력해 주세요.
          </Alert>

          <Box mt={3}>
            <Grid container mt={3} gap={3}>
              {pnrNumbers.map((pnr, index) => (
                <Grid item sx={{ flex: '1 1 20%' }} key={index}>
                  <TextField
                    size="small"
                    placeholder="PNR 입력"
                    sx={{ width: '100%' }}
                    value={pnr}
                    onChange={(e) => handlePnrChange(index, e.target.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Stack direction="row" spacing={4} mt={6} mb={6} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          onClick={() => excuteSsrApi()}
        >
          실행
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ px: 8 }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>
      </Stack>
      {pnrResult.pnr_result ? (
        <Paper className="custom-paper">
          <Box>
            <Typography variant="h5" mb={5} fontWeight={700} textAlign="center">
              업로드 결과
            </Typography>
            <Typography variant="subtitle1" mb={2} fontWeight={700}>
              1.실행 결과
            </Typography>
            <TableContainer className="custom-scrollbar" sx={{ mt: 5 }}>
              <Table className="custom-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">PNR</TableCell>
                    <TableCell align="center">총 인원</TableCell>

                    {pnrResult?.summary_df && Object.keys(pnrResult.summary_df[0])
                      .filter((key) => !['PNR', '총 인원'].includes(key)) // 'PNR', '총 인원' 제외
                      .map((key) => (
                        <TableCell key={key} align="center">{key}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {pnrResult?.summary_df && (() => {
                    const dynamicKeys = Object.keys(pnrResult.summary_df[0]).filter(
                      (key) => !['PNR', '총 인원'].includes(key)
                    );

                    if (dynamicKeys.length === 0) {
                      return null;
                    }

                    return pnrResult.summary_df.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{item?.PNR}</TableCell>
                        <TableCell align="center">{item?.['총 인원']}</TableCell>

                        {dynamicKeys.map((key) => (
                          <TableCell key={key} align="center">
                            {item?.[key] || ''}
                          </TableCell>
                        ))}
                      </TableRow>
                    ));
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle1" mt={10} mb={2} fontWeight={700}>
              2.미입력 사유
            </Typography>
            <TableContainer className="custom-scrollbar" sx={{ mt: 5 }}>
              <Table className="custom-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">PNR</TableCell>
                    <TableCell align="center">승객번호</TableCell>
                    <TableCell align="center">승객명</TableCell>
                    <TableCell align="center">비매칭데이터</TableCell>
                    <TableCell align="center">엑셀데이터</TableCell>
                    <TableCell align="center">비매칭사유</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pnrResult.unmatch_df?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{item?.PNR}</TableCell>
                      <TableCell align="center">{item?.승객번호}</TableCell>
                      <TableCell align="center">{item?.승객명}</TableCell>
                      <TableCell align="center">{item?.비매칭데이터}</TableCell>
                      <TableCell align="center">{item?.엑셀데이터}</TableCell>
                      <TableCell align="center">{item?.비매칭사유}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* 명령어 영역 */}
            <Box
              mt={10}
              sx={{ fontFamily: 'Consolas, monospace', whiteSpace: 'pre-wrap' }}
              className="gds-answer"
            >
              {convertNewlinesToBreaks(pnrResult?.pnr_result)}
            </Box>
          </Box>
        </Paper>
      ) : null}
    </DefaultLayout>
  );
}

export default SsrInternational;
