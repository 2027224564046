/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import * as React from 'react';
import { useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Alert,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { setOpenLoading, setOpenDialogPopup, useUtripContextApi } from '../../context';
import utripApi, { customCatch } from '../../utils/api/utripApi';

import DefaultLayout from '../../layouts/defaultLayout';

function NonameResult() {
  const [controller, dispatch] = useUtripContextApi();
  const nonameResultIndv = JSON.parse(sessionStorage.getItem('nonameResultIndv'));

  // Ensure nonameResultIndv is an array
  const keys = nonameResultIndv ? nonameResultIndv.map((_, index) => index) : [];

  // Excel download function
  const excelDownload = () => {
    const excelData = nonameResultIndv
      ? nonameResultIndv.map((item) => ({
        No: item['No'],
        단말기: item['단말기'],
        PNR: item['PNR'],
        항공사: item['항공사'],
        단체명: item['단체명']?.replace(/\//g, ''),
        인원: item['인원'],
        여정: item['여정']?.replace(/\//g, ''),
      }))
      : []; // Return an empty array if nonameResultIndv is null or undefined

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, '노네임예약_결과');
    XLSX.writeFile(workBook, '노네임예약_결과.xlsx');
  };
  const pnrSearch = async (item) => {
    setOpenLoading(dispatch, { showYn: true });
    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      command: item['단말기'].includes("셀커넥") ? "RT " + item['PNR'] : "*" + item['PNR'],
      gds: item['단말기'].includes("셀커넥") ? "topas" : "sabre",
    };
    try {
      const response = await customCatch(utripApi.post('/apis/func_wire/v2/get_aircraft_command', body));
      if (response.data.message === 'SUCCESS') {
        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: `${response.data.result.replace(/(?:\r\n|\r|\n)/g, '<br />')}`,
        });
      };
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'fail', showYn: true });
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  };
  return (
    <>
      <Paper className="custom-paper">
        <Typography variant="h5" fontWeight={700} mb={5} textAlign="center" gutterBottom>
          노네임단체 PNR 생성결과
        </Typography>

        <Alert severity="success" sx={{ mb: 2 }}>
          엑셀저장 시 파일저장위치 : 내 PC > 다운로드
        </Alert>

        <TableContainer>
          <Table className="custom-table" >
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: '100px' }}>
                  단말기
                </TableCell>
                <TableCell align="center" sx={{ width: '150px' }}>
                  PNR
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  단체명
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  항공사
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  인원
                </TableCell>
                <TableCell align="center" sx={{ width: '400px' }}>
                  여정
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonameResultIndv && nonameResultIndv.length > 0 ? (
                nonameResultIndv.map((item, index) => (
                  <TableRow key={index} onClick={(e) => {
                    e.stopPropagation();
                    pnrSearch(item);
                  }}>
                    <TableCell align="center" sx={{ width: '100px' }}>
                      {item['단말기']}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '150px' }}>
                      {item['PNR']}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '100px' }}>
                      {item['단체명']}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '100px' }}>
                      {item['항공사']}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '100px' }}>
                      {item['인원']}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '400px' }}>
                      {item['여정']}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            sx={{ px: 8, color: 'white' }}
            onClick={() => excelDownload()}
          >
            엑셀저장
          </Button>
        </Stack>
      </Paper>
    </>
  );
}

export default NonameResult;
